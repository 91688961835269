import { useEffect } from "react";
import { useState } from "react";
import { apiTasksGetCleaningTasksByProperty_cleanerCode } from "../../api/tasks/tasks";

import CleaningTask from "./CleaningTask";
import CreateAlertModal from "./CreateAlertModal";
import ViewAlertModal from "./ViewAlertModal";


const CleaningList = (props) => {
    const [cleanersCode, setCleanersCode] = useState(props.cleanersCode)
    const [propertyId, setPropertyId] = useState(props.propertyId);
    const [cleaningListData, setCleaningListData] = useState();
    const [loading, setLoading] = useState(true);
    const [cleaningTypeFilter, setCleaningTypeFilter] = useState(props.cleaningTypeFilter);
    const [occupancyFilter, setOccupancyFilter] = useState(props.occupancyFilter);
    const [completeFilter, setCompleteFilter] = useState(props.completeFilter);
    const [errorMessage, setErrorMessage] = useState();

    // MODALS
    const [addAlertSelectedRoom, setAddAlertSelectedRoom] = useState();
    const [addAlertModalOpen, setAddAlertModalOpen] = useState(false);

    const [viewAlertSelectedAlert, setViewAlertSelectedAlert] = useState();
    const [viewAlertSelectedRoom, setViewAlertSelectedRoom] = useState();
    const [viewAlertModalOpen, setViewAlertModalOpen] = useState(false);

    useEffect( () => {
        setCleanersCode(props.cleanersCode);
        setPropertyId(props.propertyId);
        setCleaningTypeFilter(props.cleaningTypeFilter);
        setOccupancyFilter(props.occupancyFilter);
        setCompleteFilter(props.completeFilter);
    }, [props])
    

    const updateCleaningTasks = () => {
        setLoading(true);
        const getCleaningTasks = async () => {
            apiTasksGetCleaningTasksByProperty_cleanerCode(propertyId, cleanersCode, cleaningTypeFilter, occupancyFilter, completeFilter)
                .then(data => {
                    if (data.success === 'true') {
                        setCleaningListData(data.data);
                    } else {
                        setErrorMessage(data.msg)
                    }
                    setLoading(false);
                })
                .catch(err=>{
                    console.log(err);
                    setLoading(false);
                })
        }
        getCleaningTasks();
    }

    useEffect( () => {
        updateCleaningTasks();
        const interval = setInterval(() => {
            updateCleaningTasks();
          }, 900000);

          return () => clearInterval(interval);
    }, [propertyId, cleaningTypeFilter, occupancyFilter, completeFilter])

    const handleCreateAlert = (room) => {
        setAddAlertSelectedRoom(room);
        setAddAlertModalOpen(true)
    }

    const handleAfterAlertCreated = () => {
        setAddAlertSelectedRoom(null);
        updateCleaningTasks();
        setAddAlertModalOpen(false);
    }

    const handleViewAlert = (alert, room) => {
        setViewAlertSelectedAlert(alert);
        setViewAlertSelectedRoom(room);
        setViewAlertModalOpen(true);
    }

    return (
        <>
            <div className="cleaning-page-list px-3 py-3">
            {!loading && cleaningListData.tasks.length === 0 ? 
                
                <div className="text-center py-5text-bold">
                     No tasks available
                </div> 
            : ""}
            {loading ? "Loading..." : cleaningListData.tasks.map( (task) => {
                return (
                     <CleaningTask key={task.cleaningTaskId} cleaningTask={task} dashboard={false} cleanersCode={cleanersCode} handleCreateAlert={handleCreateAlert} handleViewAlert={handleViewAlert}/>
                )
            })}
            </div>
            {addAlertSelectedRoom ? <CreateAlertModal key={addAlertModalOpen} open={addAlertModalOpen} setModalOpen={setAddAlertModalOpen} cleanersCode={cleanersCode} propertyId={propertyId} room={addAlertSelectedRoom} handleAfterAlertCreated={handleAfterAlertCreated}/> : ""}
            {viewAlertSelectedAlert ? <ViewAlertModal key={viewAlertModalOpen} open={viewAlertModalOpen} setModalOpen={setViewAlertModalOpen} alert={viewAlertSelectedAlert} room={viewAlertSelectedRoom} /> : ""}

        </>
    )

}

export default CleaningList;