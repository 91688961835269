const CleaningListViewToggler = (props) => {
    
    return (
        <>
        <button className={"btn-toggle py-1 px-2 mx-1 " + (props.tableView === "true" ? "" : "toggle-selected")} onClick={() => props.setTableView("false")}>
            <i className={"fa-solid fa-bars fa-xl toggle-icon mx-1"} ></i>
            <i className={"fa-solid fa-square fa-xl toggle-icon"}></i>    
        </button>
        <button className={"btn-toggle py-1 px-2 mx-1 " + (props.tableView === "true" ? "toggle-selected" : "")} onClick={() => props.setTableView("true")}>
            <i className={"fa-solid fa-table-list fa-xl toggle-icon"} ></i>
        </button>
        </>
    )
}

export default CleaningListViewToggler;