import { useEffect, useState } from "react";

const CleaningTaskOccupancyFilter = (props) => {

    const [occupied, setOccupied] = useState(false);
    const [vacant, setVacant] = useState(false);
    const [active, setActive] = useState( () => {
        if (occupied === vacant) {return false}
        else {return true}
    })

    useEffect( () => {
        // console.log("Refresh: ", refresh, "Checkout:", checkout) 

        if (occupied === vacant) {
            props.updateFn("")
            setActive(false)
        }
        else {
            setActive(true)
            if (vacant) {props.updateFn(0)}
            if (occupied) {props.updateFn(1)}
        }
    }, [occupied, vacant])
 
    return (
        <div className="dropdown filter">
        <button className="btn btn-filter mx-1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <i className={"fa-solid fa-filter" + (active ? " filter-active" : "")}></i>
        </button>
        <ul className="dropdown-menu p-2">
            <li className="">
                <input className="mx-1" type="checkbox" name="task-occupancy-type" id="task-filter-occupancy-occupied" checked={occupied} onChange={(e) => setOccupied(e.target.checked)}/>
                <label htmlFor="task-filter-occupancy-occupied">Occupied</label>
            </li>
            <li className="">
                <input className="mx-1" type="checkbox" name="task-occupancy-type" id="task-filter-occupancy-vacant" checked={vacant} onChange={(e) => setVacant(e.target.checked)}/>
                <label htmlFor="task-filter-occupancy-vacant">Vacant</label>
            </li>
        </ul>
        </div>
    )
}

export default CleaningTaskOccupancyFilter;