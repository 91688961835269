import { useEffect, useState } from "react";


const RoomDetailCleaningTasksPanel = (props) => {

    const [cleaningTasks, setCleaningTasks] = useState(props.cleaningTasks);
    const [room, setRoom] = useState(props.room)
    const [loading, setLoading] = useState(props.loading)

    useEffect( () => {
        setCleaningTasks(props.cleaningTasks);
        setRoom(props.room);
        setLoading(props.loading);
    }, [props])

    return (
        <div className="room-detail-cleaning-tasks-panel p-2">
            <div className="panel-title m-0 p-0">
                Cleaning Tasks
            </div>
            <div className="room-detail-cleaning-tasks-panel-last-cleaned">
                Last cleaned: {room ? room.lastCleaned : ""}
            </div>
            <div className="room-detail-cleaning-tasks-panel-total-tasks">
                Total tasks: {cleaningTasks ? cleaningTasks.length : ""}
            </div>

            {loading ? "Loading..." :
                <div className="panel-list mt-2">
                    
                    {cleaningTasks && cleaningTasks.map( (task) => {
                        return (
                            <div key={task.cleaningTaskId} className={"panel-list-item my-3 p-2 mx-auto"}>
                                {task.date}
                                <div className="room-detail-cleaning-task-panel-status my-1 mx-2">
                                    {task.taskComplete ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-minus"></i>}
                                </div>
                                <div className="room-detail-cleaning-task-panel-cleaning-type">
                                    {task.cleaningType.charAt(0).toUpperCase() + task.cleaningType.slice(1)} clean
                                </div>
                                {task.sendBack ? 
                                
                                    <div className="room-detail-cleaning-task-panel-sendback my-1 mx-2 text-bold color-error">
                                        Sendback
                                    </div>
                                
                                : ""}
                            </div>
                        )
                    })}
                    
                </div>

            }
            
        </div>
    )
}

export default RoomDetailCleaningTasksPanel;