import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { apiAlertsCloseAlert, apiAlertsGetAlertById } from "../../api/alerts/alerts";
import get_CSRF_token from "../../utilities/access_tokens";


const AlertDetail = () => {

    const {alertId} = useParams();
    const navigate = useNavigate();
    const [alert, setAlert] = useState();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const getAlertDetails = () => {
        setLoading(true);
        setErrorMessage();
        const fetchAlert = async () => {
            apiAlertsGetAlertById(alertId)
                .then(res => {
                    if (res.status === 404) {
                        navigate(-1);
                    } else if (res.status === 401) {
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/alerts/" + alertId
                            }
                        })
                    } else {
                        return res;
                    }
                })
                .then(res => res.json())
                .then(res => {
                    if (res.success === "true") {
                        setAlert(res.data.alert)
                    } else {
                        console.log(res)
                        setErrorMessage(res.msg)
                    }
                    setLoading(false)
                })
                .catch(err => console.log("ERROR: ", err))
        }
        fetchAlert();
    }

    // Only allow integer alertIds
    useEffect(() => {
        if (!/^[0-9]+$/.test(alertId)) {
          navigate(-1);
        } else {
            getAlertDetails();
        }
      }, [alertId, navigate]);

    const closeAlert = () => {
        setErrorMessage();
        const submit = async () => {
            apiAlertsCloseAlert(alertId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/alerts"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        getAlertDetails();
                    } else {
                        setErrorMessage(res.msg);
                    }
                })
                .catch(err => console.log("ERROR:", err))
        }
        submit();
    }

    useEffect( () => {
        document.title = "Alert | Glan"
    }, [])


    return (
        <>
        {loading ? "Loading..." : alert ?
        <>
        <div className="room-detail-room-info mx-1 mt-5">
            <div className="room-detail-room-name">
                <span className="text-bold"># - {alert.alertId}</span> / {alert.open ? "Open" : "Closed"}
            </div>
        </div>
        {errorMessage ?
            <div className="error-message">
                {errorMessage}
            </div>
        : "" }

        <div className="alert-detail-panel p-2 mt-3 mx-1">
            {alert.open ? 
            <div className="alert-detail-close-button m-2">
                <button className="btn-secondary" onClick={closeAlert}>
                    Close alert
                </button>
            </div>
            : "" }

            <div className="alert-detail-room">
                {alert.room.name}
            </div>
            <div className="alert-detail-description my-2">
                {alert.description}
            </div>
            <div className="alert-detail-created-date my-2">
                Created on {alert.createdDate} at {alert.createdTime}
            </div>

            {alert.open ? "" :
            <div className="alert-detail-closed-date">
                Closed on {alert.closedDate} at {alert.closedTime}
            </div>
            
            }
        </div>
       
        </>
        : ""}

        </>
    )
}

export default AlertDetail;