import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiReportingGetPreviousWeekOverview } from "../../../api/reporting/reporting";


const PreviousWeekOverview = (props) => {
    const navigate = useNavigate();
    const [propertyId, setPropertyId] = useState(props.propertyId)
    const [tasks, setTasks] = useState();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();


    const getTasks = () => {
        setLoading(true);
        const fetchTasks = async () => {
            apiReportingGetPreviousWeekOverview(propertyId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/alerts"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        setTasks(res.data)
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                    setLoading(false);
                })
        }
        fetchTasks();
    }

    useEffect( () => {
        getTasks();
    }, [])

    return (
        <>
        <div className="metric-large-title">
            Last 7 days
        </div>
        <div className="metric-large-table">
            <table className="table">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-center">Tasks</th>
                        <th className="text-center">Sendbacks</th>
                        <th className="text-center">Complete</th>
                    </tr>
                </thead>
                <tbody>

                    {loading ? 
                        <tr>
                            <td colSpan={4}>Loading</td>
                        </tr>
                        
                        : 
                            tasks ?

                                tasks.map( (task) => {
                                    return(
                                        <tr key={task.date}>
                                            <td>{task.date}</td>
                                            <td className="text-center">{task.totalTasks}</td>
                                            <td className={"text-center " + (task.totalSendBacks > 0 ? "text-error text-bold" : "")}>{task.totalSendBacks}</td>
                                            <td className="text-center">{task.totalComplete}</td>
                                        </tr>
                                    )
                                })
                            
                            :
                                <tr>
                                    <td colSpan={4}>
                                        No data available
                                    </td>
                                </tr>
                    }
                </tbody>
            </table>
        </div>
        </>
    )
}

export default PreviousWeekOverview;