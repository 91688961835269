import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { apiTasksCompleteCleaningTask, apiTasksGetCleaningTaskById, apiTasksSendBack } from "../../api/tasks/tasks";
import get_CSRF_token from "../../utilities/access_tokens";
import LoadingSpinner from "../LoadingSpinner";


const CleaningListSelectedTask = (props) => {
    const navigate = useNavigate();
    const [cleaningTask, setCleaningTask] = useState(props.cleaningTask);
    const [completeLoading, setCompleteLoading] = useState(false);
    const [sendbackLoading, setSendbackLoading] = useState(false);

    useEffect( () => {
        setCleaningTask(props.cleaningTask);
    }, [props])

    const updateCleaningTask = () => {
        const fetchTask = async () => {
            apiTasksGetCleaningTaskById(cleaningTask.cleaningTaskId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/tasks"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true"){
                        props.setSelectedTask(res.data);
                    } else {
                        console.log(res.msg)
                    }
                })
                .catch(err => console.log(err))
        }

        fetchTask();
    }

    const handleSubmit = () => {
        setCompleteLoading(true);
        const completeTask = async () => {
            apiTasksCompleteCleaningTask(cleaningTask.cleaningTaskId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/tasks"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === 'true') {
                        updateCleaningTask();
                        // console.log("complete")
                        props.updateListFn()
                    } else {
                        console.log(res.msg)
                    }
                    setCompleteLoading(false)
                })
                // .then(props.updateListFn())
                .catch(err => {
                    console.log("ERROR:", err)
                    setCompleteLoading(false)
                })
        }
        completeTask();
    } 

    const sendBackTask = () => {
        setSendbackLoading(true)
        const sendBack = async () => {
            apiTasksSendBack(cleaningTask.cleaningTaskId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/tasks"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        updateCleaningTask();
                        // console.log("sent back")
                        props.updateListFn()
                    } else {
                        console.log(res.msg);
                    }
                    setSendbackLoading(false)
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                    setSendbackLoading(false);
                })
        }
        sendBack();
    }
    

    return (
        <>
        <div className="selected-cleaning-task-id m-0 p-0">
            # - {cleaningTask.cleaningTaskId}
        </div>
        <div className="selected-cleaning-task-room m-0 p-0">
            Room: {cleaningTask.room.name}
        </div>
        <div className="selected-cleaning-task-type">
            Cleaning Type: {cleaningTask.cleaningType.charAt(0).toUpperCase() + cleaningTask.cleaningType.slice(1)}
        </div>
        <div className="selected-cleaning-task-status">
            Status: {cleaningTask.taskComplete ? "Complete" : "Outstanding"}
        </div>
        {cleaningTask.taskComplete ? 
            <div className="selected-cleaning-task-completed-time">
                Completed Time: {cleaningTask.completedTime}
            </div>
        : ""}

        {cleaningTask.sendBack ? 
            <div className="selected-cleaning-task-sendback">
                Sentback
            </div>
        : ""}

        {cleaningTask.sendBack && cleaningTask.taskComplete ?
            <div className="selected-cleaning-task-sendback-completed-time">
                Sendback Completed Time: {cleaningTask.sendBackCompletedTime}
            </div>
        : ""}


        <div className="cleaning-page-status-button my-1 mx-2">
                
                {cleaningTask.taskComplete ? 
                    <i className="fa-solid fa-circle-check"></i>
                    :
                        completeLoading ?
                            <LoadingSpinner /> 
                        :
                        <button className="p-0 m-0" onClick={handleSubmit}><i className="fa-solid fa-circle-minus"></i></button>
                }
            
            
        </div>

        {!cleaningTask.sendBack && cleaningTask.taskComplete ?
            <div className="selected-cleaning-task-sendback-button my-1 mx-2">
                <button className="btn-error" onClick={sendBackTask}>
                    {sendbackLoading ? <LoadingSpinner /> : " "}
                    Sendback
                </button>
                
            </div>
        :""}
        </>
    )
}

export default CleaningListSelectedTask;