import { useEffect } from "react"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { apiTasksGetTotalTasks } from "../../../api/tasks/tasks";


const TotalCleansMetric = (props) => {
    const navigate = useNavigate();
    const [propertyId, setPropertyId] = useState(props.propertyId)
    const [totalTasks, setTotalTasks] = useState();
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        setPropertyId(props.propertyId);
    }, [props])

    useEffect( () => {
        const getTotalTasks = async () => {
            apiTasksGetTotalTasks(propertyId, "")
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        setTotalTasks(res.data.totalTasks);
                        setLoading(false);
                    } else {
                        console.log(res.msg);
                    }
                })
                .catch(err => console.log("ERROR: ", err))
        }

        getTotalTasks();

        const interval = setInterval(() => {
            getTotalTasks();
          }, 900000);

          return () => clearInterval(interval);
    },[])

    return (
        <>
            
            <div className="metric-description">Tasks today</div>
            <div className="metric-value">
                {loading ? "Loading..." : totalTasks}
            </div>
                
        </>
    )
}

export default TotalCleansMetric;