import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { apiTasksGetCleaningTasksByProperty } from "../../api/tasks/tasks";

import CleaningTask from "../../components/cleaning/CleaningTask";
import CleaningListPanel from "./CleaningListPanel";
import CleaningListSelectedTask from "./CleaningListSelectedTask";

const CleaningListInteractiveView = (props) => {

    const navigate = useNavigate();
    const [cleaningTasks, setCleaningTasks] = useState();
    const [selectedTask, setSelectedTask] = useState();
    const [propertyId, setPropertyId] = useState(props.propertyId);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(props.selectedDate);
    const [keyCounter, setKeyCounter] = useState(0);

    useEffect( () => {
        setPropertyId(props.propertyId);
        setSelectedDate(props.selectedDate);
        // setSelectedTask();
        updateTasks();
    },[props])

    const updateTasks = () => {
        const getCleaningTasks = async () => {
            apiTasksGetCleaningTasksByProperty(propertyId, selectedDate, "", "", "")
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/tasks"
                            }
                        })
                    }
                    return res.json()
                })
                .then(data => {
                    if (data.success === 'true') {
                        setCleaningTasks(data.data.tasks);
                        setLoading(false);
                    } else {
                        console.log(data.msg)
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
        }
        setLoading(true);
        getCleaningTasks();
    }


    return (
        <div className="row mx-1 p-0 h-100">
            <div className="col-md-6 px-2 d-flex h-100 justify-content-center">
                {loading ? "Loading..." :
                    <CleaningListPanel key={loading} cleaningTasks={cleaningTasks} setSelectedTask={setSelectedTask} selectedTask={selectedTask}/>
                }
            </div>
            <div className="col-md-6 d-none d-md-block px-2 selected-item-wrapper">
                {selectedTask ?
                    <div className="selected-item my-2 p-2">
                        <CleaningListSelectedTask cleaningTask={selectedTask} updateListFn={updateTasks} setSelectedTask={setSelectedTask}/>
                    </div>
                : ""}
            </div>
        </div>
    )
}

export default CleaningListInteractiveView;