import get_CSRF_token from "../../utilities/access_tokens";

const baseURL = process.env.REACT_APP_GLAN_API_URL;

const apiAlertsGetActiveAlertByRoom_cleanerCode = (propertyId, cleanersCode, roomId) => {
    return fetch(`${baseURL}/alert/getActiveAlertByRoom?id=${roomId}`, {
        method: "GET",
        headers: {
            "X-PROPERTY-ID": propertyId,
            "X-CLEANERS-CODE": cleanersCode
        },
        credentials: "omit"
    })
        .then(res => res.json())
}

const apiAlertsCreateAlert_cleanerCode = (propertyId, cleanersCode, roomId, description) => {
    return fetch(`${baseURL}/alert/addAlert`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "X-PROPERTY-ID": propertyId,
            "X-CLEANERS-CODE": cleanersCode
        },
        credentials: "omit",
        body: JSON.stringify({"roomId": roomId, "description": description})
    })
        .then(res => res.json())
}

const apiAlertsGetAlertById = (alertId) => {
    return fetch(`${baseURL}/alert/getAlertById?id=${alertId}`, {
        credentials: "include"
    })
}

const apiAlertsCloseAlert = (alertId) => {
    return fetch(`${baseURL}/alert/closeAlert?id=${alertId}`, {
        method: "PUT",
        credentials: "include",
        headers: {
            "X-CSRF-TOKEN": get_CSRF_token()
        }
    })
}

const apiAlertsBulkCloseAlerts = (alertIds) => {
    return fetch(`${baseURL}/alert/bulkCloseAlerts`, {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "X-CSRF-TOKEN": get_CSRF_token()
        },
        body: JSON.stringify({"ids": alertIds})
    })
}

const apiAlertsGetAlertsByProperty = (propertyId, open) => {
    return fetch(`${baseURL}/alert/getAlertsByProperty?id=${propertyId}&open=${open}`, {
        credentials: "include"
    })
}

const apiAlertsGetTotalActiveAlerts = (propertyId) => {
    return fetch(`${baseURL}/alert/getTotalActiveAlerts?id=${propertyId}`, {
        credentials: "include"
    })
}

const apiAlertsGetActiveAlertByRoom = (roomId) => {
    return fetch(`${baseURL}/alert/getActiveAlertByRoom?id=${roomId}`, {
        credentials: "include"
    })
}

export {
    apiAlertsGetActiveAlertByRoom_cleanerCode,
    apiAlertsCreateAlert_cleanerCode,
    apiAlertsGetAlertById,
    apiAlertsCloseAlert,
    apiAlertsGetAlertsByProperty,
    apiAlertsGetTotalActiveAlerts,
    apiAlertsGetActiveAlertByRoom,
    apiAlertsBulkCloseAlerts
};