import get_CSRF_token from "../../utilities/access_tokens";

const baseURL = process.env.REACT_APP_GLAN_API_URL;

const apiTasksGetCleaningTasksByProperty_cleanerCode = (propertyId, cleanersCode, cleaningType, occupancy, complete) => {
    return fetch(`${baseURL}/cleaningTask/getCleaningTasksByProperty?id=${propertyId}&cleaningType=${cleaningType}&occupied=${occupancy}&complete=${complete}`, {
        method: "GET",
        headers: {
            "X-PROPERTY-ID": propertyId,
            "X-CLEANERS-CODE": cleanersCode
        },
        credentials: "omit"
    })
        .then(res => res.json())
}

const apiTasksGetCleaningTaskById_cleanerCode = (propertyId, cleanersCode, cleaningTaskId) => {
    return fetch(`${baseURL}/cleaningTask/getCleaningTaskById?id=${cleaningTaskId}`, {
        method: "GET",
        headers: {
            "X-PROPERTY-ID": propertyId,
            "X-CLEANERS-CODE": cleanersCode
        },
        credentials: "omit"
    })
        .then(res => res.json())
}

const apiTasksCompleteCleaningTask_cleanerCode = (propertyId, cleanersCode, cleaningTaskId) => {
    return fetch(`${baseURL}/cleaningTask/completeCleaningTask?id=${cleaningTaskId}`, {
        method: "PUT",
        headers: {
            "X-PROPERTY-ID": propertyId,
            "X-CLEANERS-CODE": cleanersCode
        },
        credentials: "omit"
    })
        .then(res => res.json())
}

const apiTasksGetTotalTasks = (propertyId, complete) => {
    return fetch(`${baseURL}/cleaningTask/getTotalTasks?id=${propertyId}&complete=${complete}`, {
        credentials: "include"
    })
}

const apiTasksAddCleaningTask = (cleaningType, roomId, date) => {
    return fetch(`${baseURL}/cleaningTask/addCleaningTask`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': get_CSRF_token()
        },
        body: JSON.stringify({
            "cleaningType": cleaningType,
            "taskComplete": false,
            "roomId": roomId,
            "date": date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
        })
    })
}

const apiTasksGetCleaningTasksByProperty = (propertyId, date, cleaningType, occupancy, complete) => {
    return fetch(`${baseURL}/cleaningTask/getCleaningTasksByProperty?id=${propertyId}&date=${date.getDate()}-${(date.getMonth()+1)}-${date.getFullYear()}&cleaningType=${cleaningType}&occupied=${occupancy}&complete=${complete}`, {
        credentials: "include"
    })
}

const apiTasksCompleteCleaningTask = (cleaningTaskId) => {
    return fetch(`${baseURL}/cleaningTask/completeCleaningTask?id=${cleaningTaskId}`, {
        method:"PUT",
        credentials: "include",
        headers: {
            'X-CSRF-TOKEN': get_CSRF_token()
        }
    })
}

const apiTasksBulkCompleteCleaningTask = (cleaningTaskIds) => {
    return fetch(`${baseURL}/cleaningTask/bulkCompleteCleaningTask`, {
        method:"PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': get_CSRF_token()
        },
        body: JSON.stringify({"ids": cleaningTaskIds})
    })
}

const apiTasksSendBack = (cleaningTaskId) => {
    return fetch(`${baseURL}/cleaningTask/sendBack?id=${cleaningTaskId}`, {
        method:"PUT",
        credentials: "include",
        headers: {
            "X-CSRF-TOKEN": get_CSRF_token()
        }
    })
}

const apiTasksBulkSendBack = (cleaningTaskIds) => {
    return fetch(`${baseURL}/cleaningTask/bulkSendBack`, {
        method:"PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': get_CSRF_token()
        },
        body: JSON.stringify({"ids": cleaningTaskIds})
    })
}

const apiTasksGetCleaningTaskById = (cleaningTaskId) => {
    return fetch(`${baseURL}/cleaningTask/getCleaningTaskById?id=${cleaningTaskId}`, {
        credentials: "include"
    })
}

export {
    apiTasksGetCleaningTasksByProperty_cleanerCode,
    apiTasksGetCleaningTaskById_cleanerCode,
    apiTasksCompleteCleaningTask_cleanerCode,
    apiTasksGetTotalTasks,
    apiTasksAddCleaningTask,
    apiTasksGetCleaningTasksByProperty,
    apiTasksCompleteCleaningTask,
    apiTasksBulkCompleteCleaningTask,
    apiTasksSendBack,
    apiTasksBulkSendBack,
    apiTasksGetCleaningTaskById
};