import { useEffect } from "react";
import { useState } from "react"
import { Link } from "react-router-dom"
import { apiAuthResetPassword } from "../../api/auth/auth";
import LoadingSpinner from "../../components/LoadingSpinner"


const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [success, setSuccess] = useState(false);

    const resetPassword = () => {

        const sendRequest = async () => {
            apiAuthResetPassword(email)
                .then(res => {
                    if (res.success === "true") {
                        setSuccess(true);
                    } else {
                        setErrorMessage(res.msg);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    setLoading(false);
                })
        }
        sendRequest();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        resetPassword();
    }
    
    useEffect( () => {
        document.title = "Reset your password | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#F5F5F5");
    }, [])

    return (
        <>
        <div className="d-flex flex-column container text-center align-items-center align-items-middle login-form py-5 px-4">

            <form className="my-auto" onSubmit={handleSubmit}>
                <div className="text-title"><Link to="/">Glan</Link></div>
                <div className="login-description">
                    Reset your password
                </div>

                {success ? 
                    <div className="success-message">
                        If you email is valid, you will receive a link to reset your password. If you don't receive an email, please contact us.
                        <br />
                        You must wait at least 5 minutes after your last request before requesting a new password reset.
                    </div>
                
                : "" }

                {/* EMAIL */}
                <label htmlFor="email" className='form-label mt-2'>Email:</label>
                <input id="email" className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} placeholder={"john.smith@yugo.com"} required></input>

                <div className="login-form-error-msg">
                    {errorMessage}
                </div>
                <button type="submit"  className='btn btn-login mt-3'>
                    {loading ? <LoadingSpinner /> : ""} Reset Password
                </button>
            </form>

            <div className="mt-auto">
                <Link to="/login">Login</Link>
            </div>
        </div>
        </>
    )
}

export default ForgotPassword;