import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import PasswordFields from "../../components/auth/PasswordFields";
import LoadingSpinner from "../../components/LoadingSpinner";
import { apiAuthSignup } from "../../api/auth/auth";


const Signup = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [signupSuccess, setSignupSuccess] = useState(false);
    const [name, setName] = useState()
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [passwordValid, setPasswordValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const submit = async () => {
            apiAuthSignup(name, email, password)
                .then(res => {
                    if (res.success === "true") {
                        setSignupSuccess(true)
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false);
                    console.log("ERROR: ", err)
                })
        }

        if (passwordValid){
            submit();
        } else {
            setErrorMessage("Please complete form and submit again");
            setLoading(false)
        }
    }

    useEffect( () => {
        document.title = "Sign up | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#F5F5F5");
    }, [])

    return (
        <>
        <div className="d-flex flex-column container text-center align-items-center align-items-middle login-form py-1 py-md-5 px-4">

            <form className="my-auto" onSubmit={handleSubmit}>
                <div className="text-title"><Link to="/">Glan</Link></div>
                <div className="login-description">
                    Sign up
                </div>

                {signupSuccess ?
                    <div className="signup-success my-5">
                        An account has been created for you. <br/>Please see further instructions in the email sent to you.
                        <div className="mt-5">
                            If you did not receive an email, please contact us.
                        </div>
                    </div>
                :
                <>
                {/* EMAIL */}
                <label htmlFor="name" className='form-label mt-2'>Full name:</label>
                <input id="name" className="form-control" type="text" onChange={(e) => setName(e.target.value)} placeholder={"John Smith"} required></input>
                {/* EMAIL */}
                <label htmlFor="email" className='form-label mt-2'>Email:</label>
                <input id="email" className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} placeholder={"john.smith@yugo.com"} required></input>
                {/* PASSWORD */}
                <PasswordFields 
                    setPasswordFn={setPassword}
                    setPasswordValid={setPasswordValid}
                    setErrorMessage={setErrorMessage}
                />

                <div className="login-form-error-msg">
                    {errorMessage}
                </div>
                <button type="submit"  className='btn btn-login mt-3'>
                    {loading ? <LoadingSpinner /> : ""} Signup
                </button>
                </>}
            </form>

            <div className="mt-auto">
                <Link to="/login">Already have an account?</Link>
            </div>
        </div>
        </>
    )
}

export default Signup;