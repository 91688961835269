import { useEffect, useState } from "react"
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { apiTasksAddCleaningTask } from "../../api/tasks/tasks";
import get_CSRF_token from "../../utilities/access_tokens";
import LoadingSpinner from "../LoadingSpinner";
import SearchBar from "./SearchBar";

const AddCleaningTaskModal = (props) => {
    const navigate = useNavigate()
    const [propertyId, setPropertyId] = useState(props.propertyId);
    const [open, setOpen] = useState(props.open);
    const [room, setRoom] = useState(props.room);
    const [date, setDate] = useState(new Date())
    const [cleaningType, setCleaningType] = useState("checkout");
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    useEffect( () => {
        setOpen(props.open);
        setPropertyId(props.propertyId);
    }, [props])

    const handleSubmit = () => {
        setSubmitting(true);
        const postTask = async () => {
            apiTasksAddCleaningTask(cleaningType, room.roomId, date)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/rooms"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true"){
                        props.setModalOpen(false);
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setSubmitting(false)
                })
                .catch(err => console.log("ERROR: ", err))
        }
        postTask();
    }

    return (
        <div className={"create-new-task-modal p-3 m-0 " + (open ? "" : "hide")}>

            <div className="modal-container mx-auto">
                <h1>Create new task</h1>

                <button className="modal-close-button btn-cancel m-md-1 p-1" onClick={() => props.setModalOpen(false)}>
                    Close
                </button>

                <div className="form mt-5 create-new-task-form d-flex flex-column justify-content-around">
                    <div className="my-2">
                        <label htmlFor="create-new-task-room-number" className="form-label">Enter room number:</label>
                        {room ? <input type="text" className="form-control" value={room.name} readOnly/> : ""}
                    </div>
                    <div className="my-2">
                        <label htmlFor="create-new-task-cleaning-type" className="form-label">Choose cleaning type:</label>
                        <select name="create-new-task-cleaning-type" id="create-new-task-cleaning-type" className="form-select" onChange={(e)=> setCleaningType(e.target.value)} defaultValue={cleaningType}>
                            <option value="refresh">Refresh</option>
                            <option value="checkout">Checkout</option>
                        </select>
                    </div>
                    <div className="my-2 date-selector">
                        <label htmlFor="create-new-task-date">Choose date for task:</label>
                        <DatePicker
                            dateFormat="d MMMM yyyy"
                            selected={date}
                            className={"form-control"}
                            onChange={(date) => setDate(date)}
                            calendarClassName="glan-datepicker-calendar"
                            wrapperClassName="d-block"
                        />
                    </div>

                    <div className="modal-error-message">
                        {errorMessage}
                    </div>

                    <div className="my-2 ml-auto">
                        <button className="btn-cancel mr-2" onClick={() => props.setModalOpen(false)}>Close</button>
                        <button className="btn-primary mx-2" onClick={handleSubmit}>{submitting ? <LoadingSpinner /> : " "} Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCleaningTaskModal;