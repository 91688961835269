import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import DateSelector from "../../components/dashboard/DateSelector";
import CleaningListInteractiveView from "../../components/dashboard/CleaningListInteractiveView";
import CleaningListTableView from "../../components/dashboard/CleaningListTableView";
import CleaningListViewToggler from "../../components/dashboard/CleaningListViewToggler";

const DashboardTasks = () => {
    const {setCurrentTab, currentProperty} = useOutletContext();
    const [propertyId] = useState(currentProperty.propertyId);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tableView, setTableView] = useState( () => {
        const initialValue = localStorage.getItem("dashboardCleaningListTableView");
        if (initialValue) {return initialValue;}
        else {return "false"}
    });
    

    useEffect( () => {
        setCurrentTab("tasks");
    }, [])

    useEffect( () => {
        localStorage.setItem("dashboardCleaningListTableView", tableView.toString());
    }, [tableView])

    useEffect( () => {
        document.title = "Cleaning Tasks | Glan"
    }, [])
    
    return (
        <>
        <h1 className="dashboard-title mx-1">Cleaning Tasks</h1>
        <div className="date-toggle-wrapper row mx-1">
            <div className="col-md-6">
                <DateSelector updateFn={setSelectedDate} selectedDate={selectedDate}/>
            </div>
            <div className="view-toggler col-md-6 d-none d-md-flex justify-content-end text-center">
                {/* <button className="btn-toggle py-1 px-2 mx-1">
                    <i className="fa-solid fa-xl fa-file-arrow-down"></i>
                </button>
                <button className="btn-toggle py-1 px-2 mx-1">
                    <i className="fa-solid fa-xl fa-print"></i>
                </button> */}
                
                <CleaningListViewToggler key={tableView} tableView={tableView} setTableView={setTableView}/>
            </div>
        </div>

        <div className="lists mt-3">
            {tableView && tableView === "true" ?
                <CleaningListTableView key={selectedDate} selectedDate={selectedDate} propertyId={propertyId}/>
            :
                <CleaningListInteractiveView key={selectedDate} selectedDate={selectedDate} propertyId={propertyId}/>
            }
            
            
        </div>
        </>
    )
}

export default DashboardTasks;