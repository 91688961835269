import { useEffect, useState } from "react";


const RoomDetailAlertsPanel = (props) => {

    const [alerts, setAlerts] = useState(props.alerts);
    const [room, setRoom] = useState(props.room)
    const [loading, setLoading] = useState(props.loading)
    const [totalActiveAlerts, setTotalActiveAlerts] = useState();

    useEffect( () => {
        setAlerts(props.alerts);
        setRoom(props.room);
        setLoading(props.loading);
    }, [props])

    useEffect( () => {
        if (alerts) {
            let count = 0;
            alerts.map((alert)=>{
                if (alert.open) {count++;}
            })
            setTotalActiveAlerts(count);
        }
    }, [alerts])

    return (
        <div className="room-detail-alerts-panel p-2">
            <div className="panel-title m-0 p-0">
                Alerts
            </div>
            <div className="room-detail-cleaning-tasks-panel-last-cleaned">
                {totalActiveAlerts} active alert(s)
            </div>
            <div className="room-detail-alerts-panel-total-alerts">
                Total alerts: {alerts ? alerts.length : ""}
            </div>

            {loading ? "Loading..." :
                <div className="panel-list mt-2">
                    
                    {alerts && alerts.map( (alert) => {
                        return (
                            <div key={alert.alertId} className={"panel-list-item my-3 p-2 mx-auto"}>
                                {alert.createdDate}
                                <div className="room-detail-alerts-panel-status my-1 mx-2">
                                    {!alert.open ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-minus"></i>}
                                </div>
                                <div className="room-detail-alerts-panel-description">
                                    {alert.description}
                                </div>
                            </div>
                        )
                    })}
                    
                </div>

            }
            
        </div>
    )
}

export default RoomDetailAlertsPanel;