import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import AlertOpenFilter from "../../components/dashboard/filters/AlertOpenFilter";
import get_CSRF_token from "../../utilities/access_tokens";
import LoadingSpinner from "../../components/LoadingSpinner";
import { apiAlertsBulkCloseAlerts, apiAlertsCloseAlert, apiAlertsGetAlertsByProperty } from "../../api/alerts/alerts";

const DashboardAlerts = () => {
    const {setCurrentTab, currentProperty} = useOutletContext();
    const navigate = useNavigate();
    const [alerts, setAlerts] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(true);
    const [alertsSelectAll, setAlertsSelectAll] = useState(false);
    const [alertsSelected, setAlertsSelected] = useState([]);
    const [openFilter, setOpenFilter] = useState("");
    const [closing, setClosing] = useState(false);

    const getAlerts = () => {
        setLoading(true);
        const fetchAlerts = async () => {
            apiAlertsGetAlertsByProperty(currentProperty.propertyId, openFilter)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/alerts"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        setAlerts(res.data.alerts);
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false);
                })
                .catch(err => console.log("ERROR: ", err))
        }
        fetchAlerts();
    }

    useEffect( () => {
        setCurrentTab("alerts");
        getAlerts();
    }, [])

    useEffect( () => {
        getAlerts();
        setAlertsSelected([]);
        setAlertsSelectAll(false);
    }, [openFilter])

    useEffect( () => {
        if (alerts && alerts.length === alertsSelected.length && alerts.length > 0) {setAlertsSelectAll(true);}
        else {setAlertsSelectAll(false);}
    }, [alertsSelected, alerts])

    const selectAll = (val) => {
        var arr = [];
        if (val) {alerts.forEach((alert) => {
            arr.push(alert.alertId);
            setAlertsSelectAll(true);
        })}
        else {
            setAlertsSelectAll(false);
        }
        setAlertsSelected(arr);
    }

    const handleChange = (id, val) => {
        // If box checked, add to array
        if (val) {
            setAlertsSelected([...alertsSelected, id])
        // else remove from array
        } else {
            setAlertsSelected(alertsSelected.filter(n => n !== id))
        }        
    }

    const submitSelected = () => {
        setErrorMessage("");
        const submit = async () => {
            apiAlertsBulkCloseAlerts(alertsSelected)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/alerts"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        getAlerts();
                    } else {
                        setErrorMessage(res.msg);
                    }
                    setClosing(false)
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    setClosing(false);
                })
        }

        submit()
    }

    useEffect( () => {
        getAlerts();
    }, [openFilter])

    const handleAlertClick = (id) => {
        navigate("" + id);
    }

    useEffect( () => {
        document.title = "Alerts | Glan"
    }, [])

    return (
        <>
        <h1 className="dashboard-title mx-1">Alerts</h1>

        <div className="action-buttons">
            <button className="btn-secondary" onClick={submitSelected}>{closing ? <LoadingSpinner /> : " "} Close selected</button>
        </div>

        {errorMessage ? 
        <div className="error-message mx-1">
            Error: {errorMessage}
        </div>
        :""}

        <div className="alerts mt-2">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col"><input type="checkbox" name="alert-selection" onChange={(e) => selectAll(e.target.checked)} checked={alertsSelectAll}/></th>
                        <th scope="col">Room</th>
                        <th scope="col" className="d-none d-md-table-cell alerts-table-date">Date Created</th>
                        <th scope="col" >Description</th>
                        <th scope="col" className="d-none d-md-table-cell alerts-table-status text-center">Status <AlertOpenFilter updateFn={setOpenFilter} /></th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {loading ? <tr><td className="text-center" colSpan={7}>Loading...</td></tr> :

                        alerts.length === 0 ? <tr><td className="text-center" colSpan={7}>No alerts available</td></tr> :
                            alerts.map( (alert) => {
                                return(
                                    <tr key={alert.alertId} className="glan-table-row" onClick={() => handleAlertClick(alert.alertId)}>
                                        <td><input 
                                            type="checkbox"
                                            name="alert-selection"
                                            id={"alert-" + alert.alertId} 
                                            value={alert.alertId}
                                            checked={alertsSelected.includes(alert.alertId)}
                                            onChange={(e) => {handleChange(alert.alertId, e.target.checked)}}
                                            onClick={(e)=>e.stopPropagation()}
                                            /></td>
                                        <td className="text-bold">{alert.room.name}</td>
                                        <td className="d-none d-md-table-cell alerts-table-date">{alert.createdDate}</td>
                                        <td>{alert.description}</td>
                                        <td className="text-center">{!alert.open ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-solid fa-circle-minus"></i>}</td>
                                    </tr>
                                )
                            })
                    }
                </tbody>
            </table>
        </div>
        </>
    )
}

export default DashboardAlerts;