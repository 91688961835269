import get_CSRF_token from "../../utilities/access_tokens";

const baseURL = process.env.REACT_APP_GLAN_API_URL;

const apiReportingGetPreviousWeekOverview = (propertyId) => {
    return fetch(`${baseURL}/reporting/getPreviousWeekTasks?id=${propertyId}`, {
        credentials: "include"
    })
}

const apiReportingGetNextWeekOverview = (propertyId) => {
    return fetch(`${baseURL}/reporting/getNextWeekTasks?id=${propertyId}`, {
        credentials: "include"
    })
}

const apiReportingGetForecast = (propertyId) => {
    return fetch(`${baseURL}/reporting/getForecast?id=${propertyId}`, {
        credentials: "include"
    })
}

const apiReportingGetPreviousTasksSumamry = (propertyId) => {
    return fetch(`${baseURL}/reporting/getPreviousTasksSummary?id=${propertyId}`, {
        credentials: "include"
    })
}

export {
    apiReportingGetPreviousWeekOverview,
    apiReportingGetNextWeekOverview,
    apiReportingGetForecast,
    apiReportingGetPreviousTasksSumamry
};