import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { apiReportingGetForecast } from '../../../api/reporting/reporting';
import LoadingSpinner from '../../LoadingSpinner';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );



const ForecastedTasksChartPanel = (props) => {
    const navigate = useNavigate();
    const [propertyId] = useState(props.propertyId);
    const [forecast, setForecast] = useState();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const getForecast = () => {
        setLoading(true);
        const fetchForecast = async () => {
            apiReportingGetForecast(propertyId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/reports"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        setForecast(res.data)
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                    setLoading(false);
                })
        }
        fetchForecast();
    }
    
    useEffect( () => {
        getForecast();
    }, [])

    const options = {
        plugins: {
            
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks:{
                    display: true,
                    autoSkip: true,
                    maxTicksLimit: 15,
                    maxRotation: 45,
                    minRotation: 45
                }
            },
            y: {
                stacked: true,
                beginAtZero: true
            },
        },
    };

    const labels = forecast ? forecast.map((item) => item.date.substr(item.date.indexOf(" ") + 1)) : "";
    const data = {
        labels,
        datasets: [
          {
            label: 'Checkout Cleans',
            data: forecast ? forecast.map((item) => item.totalCheckoutTasks) : "",
            backgroundColor: 'rgb(224, 102, 203)',
          },
          {
            label: 'Refresh Cleans',
            data: forecast ? forecast.map((item) => item.totalRefreshTasks) : "",
            backgroundColor: 'rgb(101, 201, 245)',
          }
        ],
    };
    return (
        <div className="report-panel-content p-2">
            <div className="report-panel-title">
                Forecasted Tasks
            </div>
            <div className="report-panel-description pb-3">
                Estimated number of tasks based on future reservations for the next 100 days. These numbers may go up or down depending on new bookings, modifications and cancellations.
            </div>
            <div className="report-panel-data my-3 w-100">
                { loading ? <LoadingSpinner /> :
                    <Bar options={options} data={data} />
                }
            </div>
        </div>
    )
}

export default ForecastedTasksChartPanel;