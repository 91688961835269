import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { apiAuthVerifyEmail } from "../../api/auth/auth";


const VerifyEmail = () => {

    const {token} = useParams();
    const navigate = useNavigate();
    const [verifying, setVerifying] = useState(true);

    const verifyEmail = () => {
        const sendVerification = async () => {
            // fetch(baseURL)
            //     .then(res => res.json())
            apiAuthVerifyEmail(token)
                .then(res => {
                    if (res.success === "true") {
                        setVerifying(false)
                    } else {
                        navigate("/error", {
                            state: {
                                "msg": res.msg
                            }
                        })
                    }
                })
        }
        sendVerification();
    }

    useEffect( () => {
        verifyEmail();
    }, [])

    useEffect( () => {
        document.title = "Verify your email | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#161F3D");
    }, [])

    return (
        <div className="not-found">
            <a href="/">
                <img src="/imgs/Glan-logo.svg" alt="" className="my-5" />
            </a>
            <h1>
                {verifying ? "Verifying email..." : "Email verified"}
            </h1>
        </div>
    )
}

export default VerifyEmail;