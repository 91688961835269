import { useEffect, useState } from "react";
import { apiAlertsCreateAlert_cleanerCode } from "../../api/alerts/alerts";
import LoadingSpinner from "../LoadingSpinner";


const CreateAlertModal = (props) => {
    const [cleanersCode] = useState(props.cleanersCode);
    const [propertyId] = useState(props.propertyId);
    const [room, setRoom] = useState(props.room);
    const [description, setDescription] = useState("");
    const [errorMessage, setErrorMessage] = useState();
    const [open, setOpen] = useState();
    const [submitting, setSubmitting] = useState(false);

    useEffect( () => {
        setRoom(props.room);
        setOpen(props.open)
    }, [props])

    const onChange = (event) => {
        setDescription(event.target.value);
    }

    const handleSubmit = () => {
        setErrorMessage("");
        setSubmitting(true)
        const submitAlert = async () => {
            apiAlertsCreateAlert_cleanerCode(propertyId, cleanersCode, room.roomId, description)
                .then(res => {
                    if (res.success === "true") {
                        props.handleAfterAlertCreated();
                    } else {
                        setErrorMessage(res.msg);
                    }
                    setSubmitting(false)
                })
                .catch(err => {
                    setSubmitting(false)
                    console.log("ERROR: ", err)
                })
        }

        submitAlert();
    }

    return (
        <>
            <div className={"create-alert-modal p-3 m-0 " + (open ? "" : "hide")}>

                <div className="modal-container mx-auto">
                    <h1>Report a problem</h1>

                    <button className="modal-close-button btn-cancel m-md-1 p-1" onClick={() => props.setModalOpen(false)}>
                        Close
                    </button>

                    <div className="form mt-5 create-new-task-form d-flex flex-column justify-content-around">
                        <div className="my-2">
                            <label htmlFor="create-new-task-room-number" className="form-label">Room number:</label>
                            {room ? <input type="text" className="form-control" value={room.name} readOnly/> : ""}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label">Enter description of problem:</label>
                            <textarea onChange={onChange} className="form-control create-alert-modal-description" id="exampleFormControlTextarea1" rows="5"></textarea>
                        </div>

                        <div className="modal-error-message">
                            {errorMessage}
                        </div>

                        <div className="my-2 ml-auto">
                            <button className="btn-cancel mr-2" onClick={() => props.setModalOpen(false)}>Cancel</button>
                            <button className="btn-primary mx-2" onClick={handleSubmit}>{submitting ? <LoadingSpinner /> : " "} Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default CreateAlertModal;