import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import LoadingSpinner from "../../components/LoadingSpinner";
import { apiAuthLogin } from "../../api/auth/auth";


const Login = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [redirectURL, setRedirectURL] = useState("/dashboard")

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const login = async () => {
            apiAuthLogin(email, password)
                .then(res => {
                    if (res.success === "true") {
                        navigate("" + redirectURL);
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    setLoading(false);
                    console.log("ERROR: ", err)
                })
        }
        login();
    }

    useEffect( () => {
        if (state){
            setRedirectURL(state.redirectURL);
        }
    }, [])

    useEffect( () => {
        document.title = "Login | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#F5F5F5");
    }, [])

    return (
        <>
        <div className="d-flex flex-column container text-center align-items-center align-items-middle login-form py-5 px-4">

            <form className="my-auto" onSubmit={handleSubmit}>
                <div className="text-title"><Link to="/">Glan</Link></div>
                <div className="login-description">
                    Login
                </div>

                {/* EMAIL */}
                <label htmlFor="email" className='form-label mt-2'>Email:</label>
                <input id="email" className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} placeholder={"john.smith@yugo.com"} required></input>
                {/* PASSWORD */}
                <label htmlFor="password" className='form-label mt-2'>Password:</label>
                <input id="password" className="form-control" type="password" onChange={(e) => setPassword(e.target.value)} minLength={8} maxLength={16} required></input>
                <div className="form-text">
                    <Link to={'/forgot-password'}>Forgot your password?</Link>
                </div>
                <div className="login-form-error-msg">
                    {errorMessage}
                </div>
                <button type="submit"  className='btn btn-login mt-3'>
                    {loading ? <LoadingSpinner /> : ""} Login
                </button>
            </form>

            <div className="mt-auto">
                <Link to="/signup">Don't have an account?</Link>
            </div>
        </div>
        </>
    )
}

export default Login;