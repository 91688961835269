import get_CSRF_token from "../../utilities/access_tokens";

const baseURL = process.env.REACT_APP_GLAN_API_URL;

const apiAuthCheckCleanersCode = async (cleanerCode) => {
    return fetch(`${baseURL}/auth/checkCleanersCode`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({"cleanersCode": cleanerCode})
    })
        .then(res => res.json())
}

const apiAuthLogin = async (email, password) => {
    return fetch(`${baseURL}/auth/login`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "email": email,
            "password": password
        })
    })
        .then(res => res.json())
}

const apiAuthSignup = async (name, email, password) => {
    return fetch(`${baseURL}/auth/createUser`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "name": name,
            "email": email,
            "password": password
        })
    })
        .then(res => res.json())
}

const apiAuthResetPassword = async (email) => {
    return fetch(`${baseURL}/auth/resetPassword`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "email": email
        })
    })
        .then(res => res.json())
}

const apiAuthUpdatePassword = async (token, password) => {
    return fetch(`${baseURL}/auth/updatePassword`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "token": token,
            "newPassword": password
        })
    })
        .then(res => res.json())
}

const apiAuthVerifyEmail = async (token) => {
    return fetch(`${baseURL}/auth/verifyEmail?token=${token}`)
        .then(res => res.json())
}

const apiAuthSignout = async () => {
    return fetch(`${baseURL}/auth/logout`, {
        credentials: "include",
        method: "POST",
        headers: {
            "X-CSRF-TOKEN": get_CSRF_token()
        }
    })
}

export {
    apiAuthCheckCleanersCode,
    apiAuthLogin,
    apiAuthSignup,
    apiAuthResetPassword,
    apiAuthUpdatePassword,
    apiAuthVerifyEmail,
    apiAuthSignout
};