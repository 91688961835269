import get_CSRF_token from "../../utilities/access_tokens";

const baseURL = process.env.REACT_APP_GLAN_API_URL;

const apiPropertiesGetUserProperties = () => {
    return fetch(`${baseURL}/property/getUserProperties`, {
        credentials: "include"
    })
}

const apiPropertiesGetCurrentCleanersCode = (propertyId) => {
    return fetch(`${baseURL}/property/getCurrentCleanersCode?id=${propertyId}`, {
        credentials: "include"
    })
}

export {
    apiPropertiesGetUserProperties,
    apiPropertiesGetCurrentCleanersCode
};