import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiRoomsGetRoomById } from "../../api/rooms/rooms";

import RoomDetailAlertsPanel from "../../components/dashboard/RoomDetailAlertsPanel";
import RoomDetailCleaningTasksPanel from "../../components/dashboard/RoomDetailCleaningTasksPanel";


const RoomDetail = () => {

    const { roomId } = useParams();
    const navigate = useNavigate();
    const [room, setRoom] = useState();
    const [alerts, setAlerts] = useState();
    const [cleaningTasks, setCleaningTasks] = useState();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState()

    const getRoomDetails = () => {
        const fetchRoom = async () => {
            apiRoomsGetRoomById(roomId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/rooms/" + roomId
                            }
                        })
                    } else if (res.status === 404) {
                        navigate(-1);
                    } else {
                        return res.json()
                    }
                })
                .then(res => {
                    if (res.success === "true") {
                        setRoom(res.data.room);
                        setAlerts(res.data.alerts);
                        setCleaningTasks(res.data.cleaningTasks);
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false);
                })
                .catch(err => {console.log("ERROR: ", err); setLoading(false);})
        }
        fetchRoom();
    }

    // Only allow integer roomIds
    useEffect(() => {
        if (!/^[0-9]+$/.test(roomId)) {
          navigate(-1);
        } else {
            getRoomDetails();
        }
      }, [roomId, navigate]);

    useEffect( () => {
        document.title = room?.name ? room.name  +" | Glan": "Room | Glan"
    }, [room])

    return (
        <>
        
        {loading ? "Loading..." : room ?
        <div className="room-detail-room-info mx-1 mt-5">
            <div className="room-detail-room-name">
                <span className="text-bold">{room.name}</span> / {room.roomClean ? "Clean" : "Dirty"}
            </div>
        </div>
       

        : ""}

        <div className="row mx-1 mt-3 panel-container justify-content-between">
            <div className="col-md-6 panel p-0 my-1">
                <RoomDetailCleaningTasksPanel key={"tasks-" + loading} loading={loading} cleaningTasks={cleaningTasks} room={room}/>
            </div>
            <div className="col-md-6 panel p-0 my-1">
                <RoomDetailAlertsPanel key={"alerts-" + loading} loading={loading} alerts={alerts} room={room} />
            </div>
        </div>
        </>
    )
}

export default RoomDetail;