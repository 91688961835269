import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiAuthCheckCleanersCode } from "../api/auth/auth";
import LoadingSpinner from "../components/LoadingSpinner";

const Home = () => {

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [cleanerCode, setCleanerCode] = useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage();
        
        const submitCleanersCode = async () => {
            apiAuthCheckCleanersCode(cleanerCode)
                .then(data => {
                    if (data.success === 'true') {
                        navigate("/cleaning", {
                            state: {
                                "property": data.data,
                                "cleanersCode": cleanerCode
                            }
                        })
                    } else {
                        setErrorMessage(data.msg);
                        setLoading(false);
                    }
                })
                .catch(err=>{
                    console.log(err);
                    setLoading(false);
                })
        }

        submitCleanersCode();
    }

    useEffect( () => {
        document.title = "Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#F5F5F5");
    }, [])

    return (
        <>
        <div className="d-flex flex-column container text-center align-items-center align-items-middle home-form py-5">

            <form className="my-auto" onSubmit={handleSubmit}>
                <div className="text-title">Glan</div>
                <label htmlFor="cleanerCodeInput" className='form-label'>Enter code to login:</label>
                <input id="cleanerCodeInput" className="form-control" type="text" pattern="\d*" onChange={e => setCleanerCode(e.target.value)} minLength={6} maxLength={6} placeholder={123456}></input>
                <div className="login-form-error-msg">
                    {errorMessage}
                </div>
                <button type="submit"  className='btn btn-login mt-3'>
                {loading ? <LoadingSpinner /> : ""} Submit
                </button>
            </form>
            

            <div className="mt-auto">
                <Link to="/login">Staff login</Link>
            </div>
        </div>
        </>
    )
}

export default Home;