import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import AddCleaningTaskModal from "../../components/dashboard/AddCleaningTaskModal";
import RoomCleaningStatusFilter from "../../components/dashboard/filters/RoomCleaningStatusFilter";
import CleaningTaskOccupancyFilter from "../../components/dashboard/filters/CleaningTaskOccupancyFilter";
import { apiRoomsBulkMarkRoomsClean, apiRoomsBulkMarkRoomsDirty, apiRoomsGetRoomsByProperty, apiRoomsMarkRoomClean, apiRoomsMarkRoomDirty } from "../../api/rooms/rooms";
import LoadingSpinner from "../../components/LoadingSpinner";

const DashboardRooms = () => {
    const {setCurrentTab, currentProperty} = useOutletContext();
    const navigate = useNavigate();

    const [rooms, setRooms] = useState();
    const [loading, setLoading] = useState(true);
    const [addTaskSelectedRoom, setAddTaskSelectedRoom] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [cleaningStatusFilter, setCleaningStatusFilter] = useState("");
    const [occupiedFilter, setOccupiedFilter] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectAllRooms, setSelectAllRooms] = useState(false);

    const [submittingClean, setSubmittingClean] = useState(false);
    const [submittingDirty, setSubmittingDirty] = useState(false);

    useEffect( () => {
        setCurrentTab("rooms");
        getRooms();
    }, [])

    const getRooms = () => {
        const fetchRooms = async () => {
            apiRoomsGetRoomsByProperty(currentProperty.propertyId, occupiedFilter, cleaningStatusFilter)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/rooms"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        setRooms(res.data.rooms);
                        setLoading(false)
                    } else {
                        console.log(res.msg)
                    }
                })
                .catch(err => console.log("ERROR: ", err))
        }
        fetchRooms();
    }


    const selectRoom = (room) => {
        setAddTaskSelectedRoom(room);
        setModalOpen(true);
    }

    const handleRoomClick = (id) => {
        navigate("" + id);
    }

    const navigateToAlert = (id) => {
        navigate("/dashboard/alerts/" + id);
    }

    useEffect( () => {
        getRooms();
    }, [cleaningStatusFilter, occupiedFilter])

    useEffect( () => {
        if (rooms && rooms.length === selectedRooms.length && selectedRooms.length > 0) {setSelectAllRooms(true);}
        else {setSelectAllRooms(false);}
    }, [selectedRooms, rooms])

    const selectAll = (val) => {
        var arr = [];
        if (val) {rooms.forEach((room) => {
            arr.push(room.roomId);
            setSelectAllRooms(true);
        })}
        else {
            setSelectAllRooms(false);
        }
        setSelectedRooms(arr);
    }

    const handleChange = (id, val) => {
        // If box checked, add to array
        if (val) {
            setSelectedRooms([...selectedRooms, id])
        // else remove from array
        } else {
            setSelectedRooms(selectedRooms.filter(n => n !== id))
        }        
    }

    const submitSelectedClean = () => {
        setSubmittingClean(true);
        setErrorMessage("")
        const submitClean = async () => {
            apiRoomsBulkMarkRoomsClean(selectedRooms)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/rooms"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        getRooms();
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setSubmittingClean(false)
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    setSubmittingClean(false)
                })
        }
        submitClean()
    }

    const submitSelectedDirty = () => {
        setSubmittingDirty(true);
        setErrorMessage("")
        const submitDirty = async () => {
            apiRoomsBulkMarkRoomsDirty(selectedRooms)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/rooms"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        getRooms();
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setSubmittingDirty(false)
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    setSubmittingDirty(false)
                })
        }

        submitDirty()
    }

    useEffect( () => {
        document.title = "Rooms | Glan"
    }, [])

    return (
        <>
        <h1 className="dashboard-title mx-1">Rooms</h1>

        <div className="action-buttons">
            <button className="btn-secondary" onClick={() => submitSelectedClean()}>{submittingClean ? <LoadingSpinner /> : " "} Mark clean</button>
            <button className="btn-secondary mx-2" onClick={() => submitSelectedDirty()}>{submittingDirty ? <LoadingSpinner /> : " "} Mark Dirty</button>
        </div>

        {errorMessage ? 
        <div className="error-message mx-1">
            Error: {errorMessage}
        </div>
        :""}

        <div className="rooms mx-1 mt-2">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th><input type="checkbox" name="rooms-selection" onChange={(e) => {e.stopPropagation(); selectAll(e.target.checked)}} checked={selectAllRooms}/></th>
                        <th scope="col">Room</th>
                        <th scope="col">Status <RoomCleaningStatusFilter updateFn={setCleaningStatusFilter} /></th>
                        <th scope="col" className="d-none d-md-table-cell">Last cleaned</th>
                        <th scope="col" className="d-none d-md-table-cell">Occupied <CleaningTaskOccupancyFilter updateFn={setOccupiedFilter} /></th>
                        <th scope="col" className="d-none d-md-table-cell">Last occupied</th>
                        <th scope="col" className=""></th>
                        <th scope="col" className=""></th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {loading ? <tr><td className="text-center" colSpan={7}>Loading...</td></tr> :
                        rooms.map( (room) => {
                            return(
                                <tr key={room.roomId} className="glan-table-row" onClick={() => {handleRoomClick(room.roomId)}}>
                                    <td><input 
                                        type="checkbox"
                                        name="room-selection"
                                        id={"room-" + room.roomId}
                                        value={room.roomId}
                                        checked={selectedRooms.includes(room.roomId)}
                                        onChange={(e) => {handleChange(room.roomId, e.target.checked)}}
                                        onClick={(e) => e.stopPropagation()}
                                        /></td>
                                    <td className="text-bold">{room.name} {room.alerts.length > 0 ? <span onClick={(e) => {e.stopPropagation(); navigateToAlert(room.alerts[0].alertId)}}><i className="fa-sharp fa-solid fa-circle-exclamation ml-1"></i></span> : ""}</td>
                                    <td>{room.roomClean ? "Clean" : "Dirty"}</td>
                                    <td className="d-none d-md-table-cell">{room.lastCleaned}</td>
                                    <td className="d-none d-md-table-cell">{room.occupied ? "Occupied" : "Vacant"}</td>
                                    <td className="d-none d-md-table-cell">{room.lastOccupied}</td>
                                    <td className="">
                                        <button className="btn-primary add-task-icon" onClick={(e) => {e.stopPropagation(); selectRoom(room)}}><i className="fa-solid fa-plus"></i></button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
        {addTaskSelectedRoom ? <AddCleaningTaskModal key={modalOpen} propertyId={currentProperty.propertyId} open={modalOpen} room={addTaskSelectedRoom} setModalOpen={setModalOpen}/> : ""}
        </>
    )
}

export default DashboardRooms;