import { useEffect, useState } from "react";

const CleaningTaskCompleteFilter = (props) => {

    const [complete, setComplete] = useState(false);
    const [outstanding, setOutstanding] = useState(false);
    const [active, setActive] = useState( () => {
        if (complete === outstanding) {return false}
        else {return true}
    })

    useEffect( () => {
        // console.log("Refresh: ", refresh, "Checkout:", checkout) 

        if (complete == outstanding) {
            props.updateFn("")
            setActive(false)
        }
        else {
            setActive(true)
            if (complete) {props.updateFn(1)}
            if (outstanding) {props.updateFn(0)}
        }
    }, [complete, outstanding])
 
    return (
        <div className="dropdown filter">
        <button className="btn btn-filter mx-1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <i className={"fa-solid fa-filter" + (active ? " filter-active" : "")}></i>
        </button>
        <ul className="dropdown-menu p-2">
            <li className="">
                <input className="mx-1" type="checkbox" name="task-filter-complete" id="task-filter-complete-complete" checked={complete} onChange={(e) => setComplete(e.target.checked)}/>
                <label htmlFor="task-filter-complete-complete">Complete</label>
            </li>
            <li className="">
                <input className="mx-1" type="checkbox" name="task-filter-complete" id="task-filter-complete-outstanding" checked={outstanding} onChange={(e) => setOutstanding(e.target.checked)}/>
                <label htmlFor="task-filter-complete-outstanding">Outstanding</label>
            </li>
        </ul>
        </div>
    )
}

export default CleaningTaskCompleteFilter;