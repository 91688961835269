import { useEffect } from "react";
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { apiAlertsGetTotalActiveAlerts } from "../../../api/alerts/alerts";


const TotalActiveAlertsMetric = (props) => {
    const navigate = useNavigate();
    const [propertyId, setPropertyId] = useState(props.propertyId);
    const [totalActiveAlerts, setTotalActiveAlerts] = useState();
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        const getTotalActiveAlerts = async () => {
            apiAlertsGetTotalActiveAlerts(propertyId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        setTotalActiveAlerts(res.data.totalAlerts);
                        setLoading(false);
                    } else {
                        console.log(res.msg);
                    }
                })
                .catch(err => console.log("ERROR: ", err))
        }

        getTotalActiveAlerts();
        const interval = setInterval(() => {
            getTotalActiveAlerts();
          }, 900000);

          return () => clearInterval(interval);
    },[])
    
    return (
        <>
            
            <div className="metric-description">Active Alerts</div>
            <div className="metric-value">
                {loading ? "Loading..." : totalActiveAlerts}
            </div>
                
        </>
    )
}

export default TotalActiveAlertsMetric;