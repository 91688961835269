import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import ForecastedTasksChartPanel from "../../components/dashboard/reporting/ForecastedTasksChartPanel";
import ForecastedTasksTablePanel from "../../components/dashboard/reporting/ForecastedTasksTablePanel";
import PreviousTasksTablePanel from "../../components/dashboard/reporting/PreviousTasksTablePanel";

const DashboardReports = () => {
    const {setCurrentTab, currentProperty} = useOutletContext();

    useEffect( () => {
        setCurrentTab("reports");
    }, [])

    useEffect( () => {
        document.title = "Reports | Glan"
    }, [])

    return (
        <>
        <h1 className="dashboard-title mx-1">Reports</h1>
        
        <div className="row mx-1 my-3 d-flex justify-content-between">
            <div className="col-lg-8 m-1 report-panel two-thirds d-none d-lg-block">
                <ForecastedTasksChartPanel propertyId={currentProperty.propertyId} />
            </div>
            <div className="col-lg-3 m-1 report-panel one-third">
                <ForecastedTasksTablePanel propertyId={currentProperty.propertyId} />
            </div>
        </div>
        <div className="row mx-1 my-3 d-flex justify-content-between">
            <div className="col-lg-12 mb-1 report-panel">
                <PreviousTasksTablePanel propertyId={currentProperty.propertyId} />
            </div>
        </div>
        </>
    )
}

export default DashboardReports;