import './App.css';

import {
  Routes,
  Route
} from "react-router-dom";

import Home from './routes/home';
import Cleaning from './routes/cleaning';
import NotFound from './routes/notfound';

// Dashboard Routes
import Dashboard from './routes/dashboard/dashboard';
import DashboardOverview from './routes/dashboard/overview';
import DashboardTasks from './routes/dashboard/tasks';
import DashboardAlerts from './routes/dashboard/alerts';
import DashboardRooms from './routes/dashboard/rooms';
import DashboardReports from './routes/dashboard/reports';
import RoomDetail from './routes/dashboard/roomdetail';
import AlertDetail from './routes/dashboard/alertdetail';
import Login from './routes/auth/login';
import Signup from './routes/auth/signup';
import VerifyEmail from './routes/auth/verifyemail';
import ErrorPage from './routes/errorpage';
import ResetPassword from './routes/auth/resetpassword';
import ForgotPassword from './routes/auth/forgotpassword';
import MaintenanceMode from './routes/maintenancemode';

function App() {

  if (process.env.REACT_APP_GLAN_MAINTENANCE_MODE === "true") {
    return (
      <Routes>
        <Route path="*" element={<MaintenanceMode />} />
      </Routes>
    )
  } else {
    return (
      <Routes>
          <Route path="/" element={<Home />} />

          {/* ERROR PAGE */}
          <Route path='/error' element={<ErrorPage />} />

          {/* AUTH */}
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/verify_email/:token' element={<VerifyEmail />} />
          <Route path="/reset_password/:token" element={<ResetPassword />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />


          <Route path="/cleaning" element={<Cleaning />} />

          <Route path="/dashboard" element={<Dashboard />}>
            <Route index element={<DashboardOverview />} />
            <Route path='tasks' element={<DashboardTasks />} />
            <Route path='alerts' element={<DashboardAlerts />} />
            <Route path='alerts/:alertId' element={<AlertDetail />} />
            <Route path='rooms' element={<DashboardRooms />} />
            <Route path='rooms/:roomId' element={<RoomDetail />} />
            <Route path='reports' element={<DashboardReports />} />
          </Route>


          <Route path="*" element={<NotFound />} />
        </Routes>
    );
  }
}

export default App;
