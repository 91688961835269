import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CleaningList from "../components/cleaning/CleaningList";

const Cleaning = () => {
    
    const navigate = useNavigate();
    const { state } = useLocation();
    const [cleanersCode, setCleanersCode] = useState();
    const [propertyId, setPropertyId] = useState();
    const [propertyName, setPropertyName] = useState();
    const [cleaningTypeFilter, setCleaningTypeFilter] = useState("");
    const [occupancyFilter, setOccupancyFilter] = useState("");
    const [completeFilter, setCompleteFilter] = useState("");
    const [loading, setLoading] = useState(true);
    
    useEffect( () => {
        if (state) {
            setCleanersCode(state.cleanersCode)
            setPropertyId(state.property.propertyId);
            setPropertyName(state.property.name);
            setLoading(false);
        } else {
            console.log("No cleaners code");
            navigate("/");
        }
    }, [])

    useEffect( () => {
        document.title = "Cleaning List | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#F5F5F5");
    }, [])

    return (
        <>
        {loading ? "" :
        <div className="container cleaning-page">
            <div className="cleaning-page-header fixed-top px-3 pt-3 mx-auto">

                <div className="text-title" id="cleaning-page-site-name">
                    {propertyName}
                </div>
                <div className="cleaning-page-filters m-0 my-2 p-0 py-1">
                    <div className="cleaning-page-filter-cleaning-type d-flex flex-row justify-content-between my-2">
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="cleaning-type-filter" value="all" id="cleaning-type-filter-all" defaultChecked onClick={() => setCleaningTypeFilter("")}/>
                            <label htmlFor="cleaning-type-filter-all" className="w-100 px-2 py-1">All</label>
                        </div>
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="cleaning-type-filter" value="checkout" id="cleaning-type-filter-checkout" onClick={() => setCleaningTypeFilter("checkout")}/>
                            <label htmlFor="cleaning-type-filter-checkout" className="w-100 px-2 py-1">Checkout</label>
                        </div>
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="cleaning-type-filter" value="refresh" id="cleaning-type-filter-refresh" onClick={() => setCleaningTypeFilter("refresh")}/>
                            <label htmlFor="cleaning-type-filter-refresh" className="w-100 px-2 py-1">Refresh</label>
                        </div>
                    </div>
                    <div className="cleaning-page-filter-occupied d-flex flex-row justify-content-between my-2">
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="occupancy-filter" value="all" id="occupancy-filter-all" defaultChecked onClick={() => setOccupancyFilter("")}/>
                            <label htmlFor="occupancy-filter-all" className="w-100 px-2 py-1">All</label>
                        </div>
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="occupancy-filter" value="0" id="occupancy-filter-checkout" onClick={() => setOccupancyFilter(0)}/>
                            <label htmlFor="occupancy-filter-checkout" className="w-100 px-2 py-1">Vacant</label>
                        </div>
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="occupancy-filter" value="1" id="occupancy-filter-refresh" onClick={() => setOccupancyFilter(1)}/>
                            <label htmlFor="occupancy-filter-refresh" className="w-100 px-2 py-1">Occupied</label>
                        </div>
                    </div>
                    <div className="cleaning-page-filter-complete d-flex flex-row justify-content-between my-2">
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="complete-filter" value="all" id="complete-filter-all" defaultChecked onClick={() => setCompleteFilter("")}/>
                            <label htmlFor="complete-filter-all" className="w-100 px-2 py-1">All</label>
                        </div>
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="complete-filter" value="0" id="complete-filter-checkout" onClick={() => setCompleteFilter(0)}/>
                            <label htmlFor="complete-filter-checkout" className="w-100 px-2 py-1">Outstanding</label>
                        </div>
                        <div className="cleaning-page-filter-option text-center">
                            <input type="radio" name="complete-filter" value="1" id="complete-filter-refresh" onClick={() => setCompleteFilter(1)}/>
                            <label htmlFor="complete-filter-refresh" className="w-100 px-2 py-1">Complete</label>
                        </div>
                    </div>
                </div>
            </div>

            <CleaningList key={"cleaning-list"} cleaningTypeFilter={cleaningTypeFilter} occupancyFilter={occupancyFilter} completeFilter={completeFilter} propertyId={propertyId} cleanersCode={cleanersCode}/>

        </div>
        }
        </>
    )
}

export default Cleaning;