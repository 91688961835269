import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { apiAlertsGetActiveAlertByRoom } from "../../api/alerts/alerts";


const CleaningTaskRow = (props) => {
    const navigate = useNavigate();
    const [cleaningTask, setCleaningTask] = useState(props.cleaningTask);
    const [activeAlert, setActiveAlert] = useState(props.cleaningTask.alert);

    useEffect( () => {
        setCleaningTask(props.cleaningTask);
        setActiveAlert(props.cleaningTask.alert)
    }, [props])


    return (
        <tr className="glan-table-row">
            <td><input type="checkbox" name="" id="" checked={props.checked} onChange={(e) => {props.updateFn(cleaningTask.cleaningTaskId, e.target.checked)}}/></td>
            <td className="text-bold">{cleaningTask.room.name}</td>
            <td className={cleaningTask.sendBack ? "sendback-task-mobile" : " "}>{cleaningTask.cleaningType.charAt(0).toUpperCase() + cleaningTask.cleaningType.slice(1)}</td>
            <td className="d-none d-md-table-cell">{cleaningTask.room.occupied ? "Occupied" : "Vacant"}</td>
            <td className="d-none d-md-table-cell color-error text-bold">{cleaningTask.sendBack ? "Sendback" : ""}</td>
            <td className="text-center">{cleaningTask.taskComplete ? <i className="fa-solid fa-circle-check"></i>  : <i className="fa-solid fa-circle-minus"></i>}</td>
        </tr>
    )
}

export default CleaningTaskRow;