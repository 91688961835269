import { useEffect } from "react";
import { useState } from "react";
import { apiAlertsGetActiveAlertByRoom_cleanerCode } from "../../api/alerts/alerts";
import { apiTasksCompleteCleaningTask_cleanerCode, apiTasksGetCleaningTaskById_cleanerCode } from "../../api/tasks/tasks";
import LoadingSpinner from "../LoadingSpinner";
import CreateAlertModal from "./CreateAlertModal";
import ViewAlertModal from "./ViewAlertModal";

const CleaningTask = (props) => {
    const [cleanersCode] = useState(props.cleanersCode);
    const [cleaningTask, setCleaningTask] = useState(props.cleaningTask);
    const [errorMessage, setErrorMessage] = useState();
    const [alert, setAlert] = useState(props.cleaningTask.alert);
    const [dashboard] = useState(props.dashboard);
    const [submitting, setSubmitting] = useState(false);

    const updateCleaningTask = () => {
        const fetchTask = async () => {
            apiTasksGetCleaningTaskById_cleanerCode(cleaningTask.room.propertyId, cleanersCode, cleaningTask.cleaningTaskId)
                .then(res => {
                    if (res.success === "true"){
                        setCleaningTask(res.data);
                    } else {
                        setErrorMessage(res.msg)
                    }
                })
                .catch(err => console.log(err))
        }

        fetchTask();
    }


    const handleSubmit = () => {
        setSubmitting(true);
        const completeTask = async () => {
            apiTasksCompleteCleaningTask_cleanerCode(cleaningTask.room.propertyId, cleanersCode, cleaningTask.cleaningTaskId)
                .then(res => {
                    // console.log(res);
                    if (res.success === 'true') {
                        updateCleaningTask();
                        // console.log("success")
                    } else {
                        setErrorMessage(res.msg);
                    }
                    setSubmitting(false)
                })
                .catch(err => {
                    console.log("ERROR:", err);
                    setSubmitting(false)
                })
                
        }
        completeTask();
    } 

    const selectDiv = () => {
        if (dashboard) {
            props.selectFn(cleaningTask);
        }
    }

    return (
        <>
        <div className={"cleaning-page-task my-2 p-2 " + (cleaningTask.sendBack ? "sendback " : "") + (props.selectedTask && dashboard ? " selected-task" : "")} id={"task-"+cleaningTask.cleaningTaskId } onClick={selectDiv}>
            <div className="row px-3">
                <div className="cleaning-page-task-room-name text-bold m-0 p-0 col-6">
                    {cleaningTask.room.name}
                </div>
                <div className="cleaning-page-task-send-back col-6 p-0 m-0">
                    {cleaningTask.sendBack ? "Send back" : ""}
                </div>
            </div>
            <div className="row px-3">
                <div className="cleaning-page-task-type m-0 p-0 col-6">
                    {cleaningTask.cleaningType === "checkout" ?
                        <>
                        <i className="fa-solid fa-person-walking-arrow-right"></i> Checkout clean
                        </>
                    : ""}
                    {cleaningTask.cleaningType === "refresh" ? 
                        <>
                        <i className="fa-solid fa-arrows-rotate"></i> Refresh clean
                        </>
                    : ""}
                    {/* {cleaningTask.cleaningType.charAt(0).toUpperCase() + cleaningTask.cleaningType.slice(1)} clean */}
                </div>

                {dashboard ? "" :
                    <div className="cleaning-page-task-occupancy-status m-0 p-0 col-6">
                        {cleaningTask.room.occupied ? 
                            <>
                            <i className="fa-solid fa-door-closed"></i> Occupied
                            </>
                        : 
                            <>
                            <i className="fa-solid fa-door-open"></i> Vacant
                            </>
                        }
                    </div>
                }
            </div>
            

            <div className="cleaning-page-status-button my-1 mx-2">
                
                    {cleaningTask.taskComplete ? 
                        <i className="fa-solid fa-circle-check"></i>
                        : 
                            dashboard ?
                                <i className="fa-solid fa-circle-minus"></i>
                            :
                                submitting ? 
                                    <LoadingSpinner /> 
                                :
                                    <button className="p-0 m-0" onClick={handleSubmit}><i className="fa-solid fa-circle-minus"></i></button>
                    }
                
                
            </div>
            <div className="cleaning-page-task-alert my-1 mx-2">
                {dashboard ? "" : alert ? 
                    <button  type="button" className="p-0 m-0 view-alert-modal-open-icon" onClick={(e) =>
                        props.handleViewAlert(alert, cleaningTask.room)
                    }>
                        <i className="fa-sharp fa-solid fa-circle-exclamation"></i>
                    </button>
                : 
                    <button  type="button" className="p-0 m-0" onClick={(e) => {
                        props.handleCreateAlert(cleaningTask.room);
                    }}>
                        <i className="fa-sharp fa-solid fa-plus"></i>
                    </button>
                }
            </div>
        </div>
        </>
    )

}

export default CleaningTask;