import { useEffect } from "react";
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { apiRoomsSearchRooms } from "../../api/rooms/rooms";


const SearchBar = (props) => {
    const navigate = useNavigate();
    const [propertyId, setPropertyId] = useState(props.propertyId);
    const [rooms, setRooms] = useState();
    const [displayResults, setDisplayResults] = useState(false);

    const handleChange = (event) => {
        const getSearchResults = async () => {
            apiRoomsSearchRooms(propertyId, event.target.value)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true"){
                        setRooms(res.data.rooms);
                    } else {
                        console.log(res.msg);
                    }
                })
                .catch(err => console.log("ERROR: " + err))
        }
        if(event.target.value.length < 4){
            setRooms(null);
            setDisplayResults(false);
        } else {
            setDisplayResults(true);
            getSearchResults();
        }
    }

    useEffect( () => {
        setPropertyId(props.propertyId);
    }, [props])

    const handleRoomClick = (id) => {
        navigate("rooms/" + id);
    }

    return(
        <>
        <div className="search-bar p-md-0 px-1">
            <input type="search" className="search-input p-2" placeholder="Search..." onChange={handleChange}/>
        </div>

        <div className="search-results">
            {displayResults ? 
                (rooms && rooms.length > 0 ? rooms.map( (room) => {
                    return(<li key={room.roomId} className="search-result p-2" onClick={() => handleRoomClick(room.roomId)}>
                                {room.name}
                                <span className="search-result-cleaning-status-icon mr-2">
                                    <i className={"fa-solid fa-spray-can-sparkles " + (room.roomClean ? "status-clean" : "status-dirty")}></i>
                                </span>
                            </li>)
                }): <div className="m-0 p-2">No results found</div>)
            : ""}

        </div>
        </>
    )
}

export default SearchBar;