import { useEffect, useState } from "react";

const CleaningTaskCleaningTypeFilter = (props) => {

    const [refresh, setRefresh] = useState(false);
    const [checkout, setCheckout] = useState(false);
    const [active, setActive] = useState( () => {
        if (refresh === checkout) {return false}
        else {return true}
    })

    useEffect( () => {
        // console.log("Refresh: ", refresh, "Checkout:", checkout) 

        if (refresh == checkout) {
            props.updateFn("")
            setActive(false)
        }
        else {
            setActive(true)
            if (refresh) {props.updateFn("refresh")}
            if (checkout) {props.updateFn("checkout")}
        }
    }, [refresh, checkout])
 
    return (
        <div className="dropdown filter">
        <button className="btn btn-filter mx-1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <i className={"fa-solid fa-filter" + (active ? " filter-active" : "")}></i>
        </button>
        <ul className="dropdown-menu p-2">
            <li className="">
                <input className="mx-1" type="checkbox" name="task-filter-type" id="task-filter-type-refresh" checked={refresh} onChange={(e) => setRefresh(e.target.checked)}/>
                <label htmlFor="task-filter-type-refresh">Refresh</label>
            </li>
            <li className="">
                <input className="mx-1" type="checkbox" name="task-filter-type" id="task-filter-type-checkout" checked={checkout} onChange={(e) => setCheckout(e.target.checked)}/>
                <label htmlFor="task-filter-type-checkout">Checkout</label>
            </li>
        </ul>
        </div>
    )
}

export default CleaningTaskCleaningTypeFilter;