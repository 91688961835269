import { useEffect, useState } from "react";


const PasswordFields = (props) => {
    const [password, setPassword] = useState();
    const [passwordCheck, setPasswordCheck] = useState();
    const [passwordValidLength, setPasswordValidLength] = useState();
    const [passwordValidUpper, setPasswordValidUpper] = useState();
    const [passwordValidLower, setPasswordValidLower] = useState();
    const [passwordValidNumber, setPasswordValidNumber] = useState();
    const [passwordValidSpecial, setPasswordValidSpecial] = useState();

    const checkPasswordsMatch = () => {
        if (passwordCheck && password !== passwordCheck) {
            props.setErrorMessage("Passwords must match")
        } else {
            props.setPasswordFn(password);
            props.setErrorMessage("");
        }
    }

    const validatePasswordLength = () => {
        if (password && password.length > 0) {
            if (password.length > 7 && password.length < 17) {setPasswordValidLength(true)}
            else {setPasswordValidLength(false)}
        }
    }
    
    const validatePasswordLowerChar = () => {
        const regex = /(?=.*[a-z])/
        setPasswordValidLower(regex.test(password))
    }

    const validatePasswordUpperChar = () => {
        const regex = /(?=.*[A-Z])/
        setPasswordValidUpper(regex.test(password))
    }

    const validatePasswordSpecialChar = () => {
        const regex = /(?=.*[?!.])/
        setPasswordValidSpecial(regex.test(password))
    }

    const validatePasswordNumber = () => {
        const regex = /(?=.*\d)/
        setPasswordValidNumber(regex.test(password))
    }

    useEffect( () => {
        checkPasswordsMatch();
        if (passwordValidLength && passwordValidLower && passwordValidUpper && passwordValidNumber && passwordValidSpecial){
            props.setPasswordValid(true)
        }
    }, [password, passwordCheck])

    useEffect( () => {
        validatePasswordLength();
        validatePasswordUpperChar();
        validatePasswordLowerChar();
        validatePasswordNumber();
        validatePasswordSpecialChar();
    }, [password])

    return (
        <>
        {/* PASSWORD */}
        <label htmlFor="password" className='form-label mt-2'>Password:</label>
        <input id="password" className="form-control" type="password" onChange={(e) => setPassword(e.target.value)} minLength={8} maxLength={16} required></input>
        <div id="passwordHelp" className="form-text">
            Must be <span className={"text-bold " + (password ? passwordValidLength ? "text-success" : "text-error" : "")}>between 8 and 16 characters</span> and contain <span className={"text-bold " + (password ? passwordValidUpper ? "text-success" : "text-error" : "")}>uppercase</span> and <span className={"text-bold " + (password ? passwordValidLower ? "text-success" : "text-error" : "")}>lowercase</span> letters, <span className={"text-bold " + (password ? passwordValidNumber ? "text-success" : "text-error" : "")}>numbers</span> and <span className={"text-bold " + (password ? passwordValidSpecial ? "text-success" : "text-error" : "")}>special characters (?!.)</span>.
        </div>
        {/* CONFIRM PASSWORD */}
        <label htmlFor="confirm-password" className='form-label mt-2'>Confirm password:</label>
        <input id="confirm-password" className="form-control" type="password" onChange={(e) => setPasswordCheck(e.target.value)} minLength={8} maxLength={16} required></input>

        </>
    )
}

export default PasswordFields;