import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import Sidebar from "../../components/dashboard/Sidebar";
import Header from "../../components/dashboard/Header";
import { apiPropertiesGetUserProperties } from "../../api/properties/propeties";


const Dashboard = () => {
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState("");
    const [currentProperty, setCurrentProperty] = useState();
    const [properties, setProperties] = useState();

    const getUserProperties = async () => {
        const sendRequest = async () => {
            apiPropertiesGetUserProperties()
                .then(res => {
                    if (res.status === 401) {
                        navigate("/login");
                    } else {
                        return res.json()
                    }
                })
                .then(res => {
                    if (res.success === "true"){
                        if (res.data.properties.length === 0) {
                            navigate("/error", {
                                state: {
                                    "msg": "You do not have access to any properties. If you think this is an error, please contact support@glan.it."
                                }
                            })
                        }
                        setProperties(res.data.properties);
                        setCurrentProperty(res.data.properties[0])
                    } 
                    else {
                        navigate("/error", {
                            state: {
                                "msg": "Oops, something went wrong with your property access"
                            }
                        })
                    }
                })
                .catch(err => console.log("ERROR:" + err))
        }
        sendRequest();
    }
    
    useEffect( () => {
        getUserProperties();
    }, [])

    useEffect( () => {
        document.title = "Dashboard | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#161F3D");
    }, [])

    return (
        <>
        <div id="sidebar">
            <Sidebar currentTab={currentTab}/>
        </div>
        <div id="dashboard-content" className="py-3 px-2">
            <Header currentProperty={currentProperty} properties={properties} updatePropertyFn={setCurrentProperty}/>
            {currentProperty ? <Outlet key={currentProperty.propertyId} context={{setCurrentTab, currentProperty}}/> : ""}
        </div>
        
        </>
    )
}

export default Dashboard;