import { useEffect } from "react"
import { useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { apiAuthUpdatePassword } from "../../api/auth/auth"
import PasswordFields from "../../components/auth/PasswordFields"
import LoadingSpinner from "../../components/LoadingSpinner"


const ResetPassword = () => {
    const {token} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState();
    const [passwordValid, setPasswordValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const resetPassword = () => {
        const sendReset = async () => {
            apiAuthUpdatePassword(token, password)
                .then(res => {
                    if (res.success === "true") {
                        navigate("/login");
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    setLoading(false);
                })
        }
        sendReset();
    }

    const handleSubmit = () => {
        setLoading(true)
        if (passwordValid) {
            resetPassword()
        } else {
            setErrorMessage("Please fix errors and submit again")
            setLoading(false)
        }
    }

    useEffect( () => {
        document.title = "Update your password | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#F5F5F5");
    }, [])

    return (
        
        <>
        <div className="d-flex flex-column container text-center align-items-center align-items-middle login-form py-5 px-4">

            <form className="my-auto" onSubmit={handleSubmit}>
                <div className="text-title"><Link to="/">Glan</Link></div>
                <div className="login-description">
                    Reset your password
                </div>

                <PasswordFields 
                    setPasswordFn={setPassword}
                    setPasswordValid={setPasswordValid}
                    setErrorMessage={setErrorMessage}
                />
                <div className="login-form-error-msg">
                    {errorMessage}
                </div>
                <button type="submit"  className='btn btn-login mt-3' onClick={(e) => {e.preventDefault(); handleSubmit();}}>
                    {loading ? <LoadingSpinner /> : ""} Update password
                </button>
            </form>

            <div className="mt-auto">
                <Link to="/signup">Don't have an account?</Link>
            </div>
        </div>
        </>
    )
}

export default ResetPassword;