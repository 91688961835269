import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiTasksBulkCompleteCleaningTask, apiTasksBulkSendBack, apiTasksCompleteCleaningTask, apiTasksGetCleaningTasksByProperty, apiTasksSendBack } from "../../api/tasks/tasks";
import DashboardAlerts from "../../routes/dashboard/alerts";
import get_CSRF_token from "../../utilities/access_tokens";
import LoadingSpinner from "../LoadingSpinner";
import CleaningTaskRow from "./CleaningTaskRow";
import CleaningTaskCleaningTypeFilter from "./filters/CleaningTaskCleaningTypeFilter";
import CleaningTaskCompleteFilter from "./filters/CleaningTaskCompleteFilter";
import CleaningTaskOccupancyFilter from "./filters/CleaningTaskOccupancyFilter";

const CleaningListTableView = (props) => {

    const navigate = useNavigate();
    const [cleaningTasks, setCleaningTasks] = useState();
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [propertyId, setPropertyId] = useState(props.propertyId);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(props.selectedDate);
    const [errorMessage, setErrorMessage] = useState("");
    const [cleaningTypeFilter, setCleaningTypeFilter] = useState("");
    const [occupancyFilter, setOccupancyFilter] = useState("");
    const [completeFilter, setCompleteFilter] = useState("");
    const [completeLoading, setCompleteLoading] = useState(false);
    const [sendbackLoading, setSendbackLoading] = useState(false);

    useEffect( () => {
        setPropertyId(props.propertyId);
        setSelectedDate(props.selectedDate);
        // setSelectedTask();
        setSelectAll(false);
        setSelectedTasks([]);
        updateTasks();
        // setErrorMessage("");
    },[props])

    const updateTasks = () => {
        const getCleaningTasks = async () => {
            apiTasksGetCleaningTasksByProperty(propertyId, selectedDate, cleaningTypeFilter, occupancyFilter, completeFilter)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/tasks"
                            }
                        })
                    }
                    return res.json()
                })
                .then(data => {
                    if (data.success === 'true') {
                        // console.log(data.data.tasks)
                        setCleaningTasks(data.data.tasks);
                        setLoading(false);
                    } else {
                        console.log(data.msg)
                    }
                })
                .catch(err=>{
                    console.log(err);
                })
        }
        setLoading(true);
        getCleaningTasks();
    }

    // useEffect(()=>{console.log(selectedTasks)},[selectedTasks])

    useEffect( () => {
        if (cleaningTasks && cleaningTasks.length === selectedTasks.length && cleaningTasks.length > 0) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedTasks, cleaningTasks])

    const selectAllTasks = (val) => {
        var arr = [];
        if (val) {
            cleaningTasks.forEach((task) => {
                arr.push(task.cleaningTaskId)
            })
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
        setSelectedTasks(arr);
    }

    const handleCheckboxChange = (id, val) => {
        if (val) {
            setSelectedTasks([...selectedTasks, id])
        } else {
            setSelectedTasks(selectedTasks.filter(n => n !== id))
        }
    }

    const submitComplete = () => {
        setErrorMessage("");
        setCompleteLoading(true)
        const submit = async () => {
            apiTasksBulkCompleteCleaningTask(selectedTasks)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/tasks"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        updateTasks();
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setCompleteLoading(false)
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    setCompleteLoading(false)
                })
        }

        // selectedTasks.forEach((taskId) => {
        //     setCompleteLoading(true)
        //     submit(taskId);
        // })
        submit();
    }

    const submitSendback = () => {
        setErrorMessage("");
        setSendbackLoading(true);
        const submit = async () => {
            apiTasksBulkSendBack(selectedTasks)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/tasks"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        updateTasks();
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setSendbackLoading(false);
                })
                .catch(err => {
                    console.log("ERROR: ", err);
                    setSendbackLoading(false);
                })
        }
        submit();
    }

    useEffect(() => {
        updateTasks();
        setSelectAll(false);
        setSelectedTasks([]);
    }, [cleaningTypeFilter, occupancyFilter, completeFilter])


    return (
        <>
        <div className="action-buttons">
            <button className="btn-secondary" onClick={submitComplete}>{completeLoading ? <LoadingSpinner /> : " "} Mark complete</button>
            <button className="btn-error mx-2" onClick={submitSendback}>{sendbackLoading ? <LoadingSpinner /> : " "} Sendback</button>
        </div>

        {errorMessage ? 
            <div className="error-message mx-1">
                Error: {errorMessage}
            </div>
        :""}
        <div className="tasks-table mx-1 mt-2">
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope="col"><input type="checkbox" checked={selectAll} onChange={(e)=>selectAllTasks(e.target.checked)}/></th>
                        <th scope="col">Room</th>
                        <th scope="col">Cleaning Type <CleaningTaskCleaningTypeFilter updateFn={setCleaningTypeFilter}/></th>
                        <th scope="col" className="d-none d-md-table-cell">Occupied <CleaningTaskOccupancyFilter updateFn={setOccupancyFilter} /></th>
                        <th scope="col" className="d-none d-md-table-cell">Sendback</th>
                        <th scope="col" className="text-center">Status <CleaningTaskCompleteFilter updateFn={setCompleteFilter} /></th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {loading ? <tr><td className="text-center" colSpan={7}>Loading...</td></tr> :

                        cleaningTasks && cleaningTasks.length === 0 ? <tr><td className="text-center" colSpan={7}>No tasks available</td></tr> :
                            cleaningTasks.map( (task) => {
                                return(
                                    <CleaningTaskRow key={task.cleaningTaskId} cleaningTask={task} checked={selectedTasks.includes(task.cleaningTaskId)} updateFn={handleCheckboxChange}/>
                                )
                            })
                    }
                </tbody>
            </table>
        </div>
        </>
    )
}

export default CleaningListTableView;