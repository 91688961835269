import { useState } from "react"
import { forwardRef } from "react";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DateSelector = (props) => {
    // const [selectedDate, setSelectedDate] = useState(new Date());

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="date-selector-date" onClick={onClick} ref={ref}>
            {value}
        </div>
    ));

    const handleIncrement = (increment) => {
        // console.log("Increment: ", increment);
        let updatedDate = new Date(props.selectedDate);
        updatedDate.setDate(updatedDate.getDate() + increment);
        props.updateFn(updatedDate);
    }

    return (
        <div className="date-selector d-flex flex-row justify-content-md-start justify-content-around mx-1">
            <div className="date-selector-previous-arrow" onClick={() => handleIncrement(-1)}>
                <i className="fa-solid fa-caret-left fa-xl"></i>
            </div>
                <DatePicker
                dateFormat="d MMMM yyyy"
                selected={props.selectedDate}
                onChange={(date) => props.updateFn(date)}
                customInput={<ExampleCustomInput />}
                calendarClassName="glan-datepicker-calendar"
            />
            <div className="date-selector-next-arrow" onClick={() => handleIncrement(1)}>
                <i className="fa-solid fa-caret-right fa-xl"></i>
            </div>
        </div>
    );
}

export default DateSelector;