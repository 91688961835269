import { useEffect } from "react";
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { apiTasksGetTotalTasks } from "../../../api/tasks/tasks";


const TotalTasksCompleteMetric = (props) => {
    const navigate = useNavigate();
    const [propertyId, setPropertyId] = useState(props.propertyId);
    const [totalTasksComplete, setTotalTasksComplete] = useState();
    const [loading, setLoading] = useState();

    useEffect( () => {
        const getTotalCleans = async () => {
            apiTasksGetTotalTasks(propertyId, "1")
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true"){
                        setTotalTasksComplete(res.data.totalTasks);
                        setLoading(false);
                    } else {
                        console.log(res.msg);
                    }
                })
                .catch(err => console.log("ERROR: ", err))
        }
        getTotalCleans();

        const interval = setInterval(() => {
            getTotalCleans();
          }, 900000);

          return () => clearInterval(interval);        
    })

    return (
        <>
        <div className="metric-description">Complete</div>
        <div className="metric-value">{loading ? "Loading..." : totalTasksComplete}</div>
        </>
    )
}

export default TotalTasksCompleteMetric;