import { useEffect, useState } from "react";

const RoomCleaningStatusFilter = (props) => {

    const [clean, setClean] = useState(false);
    const [dirty, setDirty] = useState(false);
    const [active, setActive] = useState( () => {
        if (clean === dirty) {return false}
        else {return true}
    })

    useEffect( () => {
        // console.log("Refresh: ", refresh, "Checkout:", checkout) 

        if (clean === dirty) {
            props.updateFn("")
            setActive(false)
        }
        else {
            setActive(true)
            if (dirty) {props.updateFn(0)}
            if (clean) {props.updateFn(1)}
        }
    }, [clean, dirty])
 
    return (
        <div className="dropdown filter">
        <button className="btn btn-filter mx-1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            <i className={"fa-solid fa-filter" + (active ? " filter-active" : "")}></i>
        </button>
        <ul className="dropdown-menu p-2">
            <li className="">
                <input className="mx-1" type="checkbox" name="room-status-filter" id="room-filter-status-clean" checked={clean} onChange={(e) => setClean(e.target.checked)}/>
                <label htmlFor="room-filter-status-clean">Clean</label>
            </li>
            <li className="">
                <input className="mx-1" type="checkbox" name="room-status-filter" id="room-filter-status-dirty" checked={dirty} onChange={(e) => setDirty(e.target.checked)}/>
                <label htmlFor="room-filter-status-dirty">Dirty</label>
            </li>
        </ul>
        </div>
    )
}

export default RoomCleaningStatusFilter;