import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiAuthSignout } from "../../api/auth/auth";
import get_CSRF_token  from "../../utilities/access_tokens"

const Sidebar = (props) => {
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState(props.currentTab);

    useEffect( () => {
        setCurrentTab(props.currentTab);
    }, [props])

    const signout = () => {
        const sendSignOut = async () => {
            apiAuthSignout()
                .then(res => {
                    if (res.status === 401){
                        navigate("/");
                    }
                    return res.json();
                })
                .then(res => {
                    if (res.success === "true") {
                        navigate("/")
                    } else {
                        console.log(res.msg)
                    }
                })
                .catch(err => console.log("ERROR: ", err))
        }
        sendSignOut();
    }

    return (
        <>
            <div className="navigation-logo py-2 my-5 text-center">
                <Link to={"/"}>
                    <img src="/imgs/Glan-logo.svg" alt="" className="logo" />
                </Link>
            </div>
            <div className="navigation-item my-md-5 py-3 p-md-2">
                <Link to={"/dashboard"} className={(currentTab === "overview" ? "active" : "")}><i className="fa-solid fa-grip fa-2xl"></i></Link>
            </div>
            <div className="navigation-item my-md-5 py-3 p-md-2">
                <Link to={"tasks"} className={(currentTab === "tasks" ? "active" : "")}><i className="fa-solid fa-spray-can-sparkles fa-2xl"></i></Link>
            </div>
            <div className="navigation-item my-md-5 py-3 p-md-2">
                <Link to={"rooms"} className={(currentTab === "rooms" ? "active" : "")}><i className="fa-solid fa-bed fa-2xl"></i></Link>
            </div>
            <div className="navigation-item my-md-5 py-3 p-md-2">
                <Link to={"alerts"} className={(currentTab === "alerts" ? "active" : "")}><i className="fa-solid fa-circle-exclamation fa-2xl"></i></Link>
            </div>
            <div className="navigation-item my-md-5 py-3 p-md-2 d-none d-md-block">
                <Link to={"reports"} className={(currentTab === "reports" ? "active" : "")}><i className="fa-solid fa-chart-line fa-2xl"></i></Link>
            </div>
            <div className="navigation-item mt-auto py-3 p-md-2" onClick={() => signout()}>
                <i className="fa-solid fa-right-from-bracket fa-2xl"></i>
            </div>
        </>
    );
}

export default Sidebar;