import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiReportingGetForecast } from '../../../api/reporting/reporting';
import LoadingSpinner from '../../LoadingSpinner';

const ForecastedTasksTablePanel = (props) => {
    const navigate = useNavigate();
    const [propertyId] = useState(props.propertyId);
    const [forecast, setForecast] = useState();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const getForecast = () => {
        setLoading(true);
        const fetchForecast = async () => {
            apiReportingGetForecast(propertyId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/reports"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        setForecast(res.data)
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                    setLoading(false);
                })
        }
        fetchForecast();
    }
    
    useEffect( () => {
        getForecast();
    }, [])

    return (
        <div className="report-panel-content p-2">
            <div className="report-panel-title">
                Forecasted Tasks
            </div>
            <div className="report-panel-description pb-3">
                Estimated number of tasks based on future reservations for the next 100 days. These numbers may go up or down depending on new bookings, modifications and cancellations.
            </div>
            <div className="report-panel-data w-100">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="text-center">Total</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? 
                            <tr>
                                <td colSpan={2}><LoadingSpinner /></td>
                            </tr>
                            
                            : 
                                forecast ?

                                    forecast.map( (task) => {
                                        return(
                                            <tr key={task.date}>
                                                <td>{task.date}</td>
                                                <td className="text-center">{task.totalTasks}</td>
                                            </tr>
                                        )
                                    })
                                
                                :
                                    <tr>
                                        <td colSpan={2}>
                                            No data available
                                        </td>
                                    </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ForecastedTasksTablePanel;