import get_CSRF_token from "../../utilities/access_tokens";

const baseURL = process.env.REACT_APP_GLAN_API_URL;

const apiRoomsSearchRooms = (propertyId, searchTerm) => {
    return fetch(`${baseURL}/room/searchRooms?id=${propertyId}&q=${searchTerm}`, {
        credentials: "include"
    })
}

const apiRoomsGetRoomById = (roomId) => {
    return fetch(`${baseURL}/room/getRoomById?id=${roomId}`, {
        credentials: "include"
    })
}

const apiRoomsGetRoomsByProperty = (propertyId, occupied, cleaningStatus) => {
    return fetch(`${baseURL}/room/getRoomsByProperty?id=${propertyId}&occupied=${occupied}&clean=${cleaningStatus}`, {
        credentials: "include"
    })
}

const apiRoomsBulkMarkRoomsClean = (roomIds) => {
    console.log(JSON.stringify({"ids": roomIds}))
    return fetch(`${baseURL}/room/bulkMarkRoomsClean`, {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "X-CSRF-TOKEN": get_CSRF_token()
        },
        body: JSON.stringify({"ids": roomIds})
    })
}

const apiRoomsBulkMarkRoomsDirty = (roomIds) => {
    return fetch(`${baseURL}/room/bulkMarkRoomsDirty`, {
        method: "PUT",
        credentials: "include",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "X-CSRF-TOKEN": get_CSRF_token()
        },
        body: JSON.stringify({"ids": roomIds})
    })
}

export {
    apiRoomsSearchRooms,
    apiRoomsGetRoomById,
    apiRoomsGetRoomsByProperty,
    apiRoomsBulkMarkRoomsClean,
    apiRoomsBulkMarkRoomsDirty
};