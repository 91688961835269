import { useState } from "react";

const ViewAlertModal = (props) => {
    const [alert] = useState(props.alert);
    const [room] = useState(props.room);



    return (
        <>

        <div className={"view-alert-modal p-3 m-0 " + (props.open ? "" : "hide")}>

                <div className="modal-container mx-auto">
                    <h1>Alert for {room.name}</h1>

                    <button className="modal-close-button btn-cancel m-md-1 p-1" onClick={() => props.setModalOpen(false)}>
                        Close
                    </button>

                    <div className="mt-5 d-flex flex-column justify-content-around">
                        <div className="view-alert-modal-room-info text-bold mb-3 mt-5">
                            Alert for {room.name}
                        </div>
                        <div className="view-alert-modal-description">
                            {alert.description}
                        </div>
                        <div className="view-alert-modal-subtext my-2">
                            Created on {alert.createdDate} at {alert.createdTime}
                        </div>

                        <div className="my-2 ml-auto">
                            <button className="btn-cancel mr-2" onClick={() => props.setModalOpen(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
    </>

    );
}

export default ViewAlertModal;