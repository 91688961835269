import { useEffect } from "react"
import { useState } from "react"
import { useLocation } from "react-router-dom"

const ErrorPage = () => {
    const {state} = useLocation();
    const [errorMessage, setErrorMessage] = useState("")
    useEffect( () => {
        if (state) {
            setErrorMessage(state.msg)
        } else {
            setErrorMessage("An unknown error occured. Please try again or contact support.")
        }
    }, [])

    useEffect( () => {
        document.title = "Oops... | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#161F3D");
    }, [])
    
    return (
        <div className="not-found">
            <a href="/">
                <img src="/imgs/Glan-logo.svg" alt="" className="my-5" />
            </a>
            <div className="error-page-message">
                {errorMessage}
            </div>

        </div>
    )
}

export default ErrorPage;