import { useEffect } from "react";

const NotFound = () => {
    useEffect( () => {
        document.title = "Oops... | Glan";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#161F3D");
    }, [])

    return (
        <>
        <div className="not-found">
            <img src="/imgs/Glan-logo.svg" alt="" className="my-5" />
            <h1>Page Not Found</h1>
        </div>
        
        </>
    )
}

export default NotFound;