import { useEffect, useState } from "react"
import CleaningTask from "../cleaning/CleaningTask";


const CleaningListPanel = (props) => {

    const [cleaningTasks, setCleaningTasks] = useState(props.cleaningTasks);
    
    useEffect( () => {
        setCleaningTasks(props.cleaningTasks);
    },[props])

    return (
        <div className="list px-1 w-100">
            {cleaningTasks && cleaningTasks.length === 0 ? <div className="text-center">No tasks available</div> : cleaningTasks.map( (task) => {
                return(<CleaningTask key={task.cleaningTaskId} cleaningTask={task} dashboard={true} selectFn={props.setSelectedTask} selectedTask={props.selectedTask && task ? props.selectedTask.cleaningTaskId === task.cleaningTaskId : false}/>)
            })}
        </div>
    )

}

export default CleaningListPanel;