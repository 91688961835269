import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiReportingGetPreviousTasksSumamry } from "../../../api/reporting/reporting";
import LoadingSpinner from "../../LoadingSpinner";


const PreviousTasksTablePanel = (props) => {
    const navigate = useNavigate();
    const [propertyId, setPropertyId] = useState(props.propertyId)
    const [tasks, setTasks] = useState();
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const getTasks = () => {
        setLoading(true);
        const fetchTasks = async () => {
            apiReportingGetPreviousTasksSumamry(propertyId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard/alerts"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true") {
                        setTasks(res.data)
                    } else {
                        setErrorMessage(res.msg)
                    }
                    setLoading(false);
                })
                .catch(err => {
                    console.log("ERROR: ", err)
                    setLoading(false);
                })
        }
        fetchTasks();
    }

    useEffect( () => {
        getTasks();
    }, [])

    return (
        <div className="report-panel-content p-2">
            <div className="report-panel-title">
                Previous Tasks
            </div>
            <div className="report-panel-description">
                Number of tasks that were completed on each day by clean type for the last 30 days.
            </div>
            <div className="report-panel-data my-3 w-100">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th></th>
                            <th className="text-center" colSpan={3}>Checkout</th>
                            <th className="text-center" colSpan={3}>Refresh</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th className="text-center">Scheduled</th>
                            <th className="text-center">Send backs</th>
                            <th className="text-center">Complete</th>
                            <th className="text-center">Scheduled</th>
                            <th className="text-center">Send backs</th>
                            <th className="text-center">Complete</th>
                        </tr>
                    </thead>
                    <tbody>

                        {loading ? 
                            <tr>
                                <td className="text-center" colSpan={7}><LoadingSpinner/></td>
                            </tr>
                            
                            : 
                                tasks ?

                                    tasks.map( (task) => {
                                        return(
                                            <tr key={task.date}>
                                                <td>{task.date}</td>
                                                <td className="text-center">{task.totalCheckoutTasksScheduled}</td>
                                                <td className={"text-center " + (task.totalCheckoutTasksSentBack > 0 ? "text-error text-bold" : "")}>{task.totalCheckoutTasksSentBack}</td>
                                                <td className="text-center">{task.totalCheckoutTasksComplete}</td>
                                                <td className="text-center">{task.totalRefreshTasksScheduled}</td>
                                                <td className={"text-center " + (task.totalRefreshTasksSentBack > 0 ? "text-error text-bold" : "")}>{task.totalRefreshTasksSentBack}</td>
                                                <td className="text-center">{task.totalRefreshTasksComplete}</td>
                                            </tr>
                                        )
                                    })
                                
                                :
                                    <tr>
                                        <td className="text-center" colSpan={7}>
                                            No data available
                                        </td>
                                    </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PreviousTasksTablePanel;