import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiPropertiesGetCurrentCleanersCode } from "../../../api/properties/propeties";


const CurrentCleanersCodeMetric = (props) => {
    const navigate = useNavigate();
    const [propertyId, setPropertyId] = useState(props.propertyId);
    const [currentCleanersCode, setCurrentCleanersCode] = useState();
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        const getCurrentCleanersCode = async () => {
            apiPropertiesGetCurrentCleanersCode(propertyId)
                .then(res => {
                    if (res.status === 401){
                        navigate("/login", {
                            state: {
                                "redirectURL": "/dashboard"
                            }
                        })
                    }
                    return res.json()
                })
                .then(res => {
                    if (res.success === "true"){
                        setCurrentCleanersCode(res.data.currentCleanersCode);
                        setLoading(false);
                    } else {
                        console.log(res.msg);
                    }
                })
                .catch(err => console.log("ERROR: ", err))
        }
        getCurrentCleanersCode();
    })


    return (
        <>
        <div className="metric-description">Cleaners Code</div>
        <div className="metric-value">{loading ? "Loading..." : currentCleanersCode}</div>
        </>
    )
}

export default CurrentCleanersCodeMetric;