import { useEffect } from "react";
import { useState } from "react";
import SearchBar from "./SearchBar";

const Header = (props) => {

    const [currentProperty, setCurrentProperty] = useState(props.currentProperty);
    const [properties, setProperties] = useState(props.properties);

    useEffect( () => {
        setCurrentProperty(props.currentProperty);
        setProperties(props.properties);
    }, [props])

    const handlePropertyChange = (property) => {
        props.updatePropertyFn(property);
    }

    return (
        <div className="row mb-4 header-row w-100">
            <div className="col-md-6 mb-2 p-0 mx-auto mx-md-0">
                <div className="property-dropdown dropdown-center">
                    <button className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        {currentProperty ? currentProperty.name : ""}
                    </button>
                    <ul className="dropdown-menu">
                        {properties ? properties.map( (property) => {
                            return(<li key={property.propertyId} className="dropdown-item" onClick={()=>handlePropertyChange(property)}>{property.name}</li>)
                        }):""}
                    </ul>
                </div>
            </div>
            <div className="col-md-6 m-0 p-0 search-wrapper d-flex flex-column">
                {currentProperty? <SearchBar propertyId={currentProperty.propertyId} /> : ""}
            </div>
        </div>
    );
}

export default Header;