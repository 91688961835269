import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import TotalCleansMetric from "../../components/dashboard/metrics/TotalCleansMetric";
import CurrentCleanersCodeMetric from "../../components/dashboard/metrics/CurrentCleanersCodeMetric";
import TotalTasksCompleteMetric from "../../components/dashboard/metrics/TotalTasksCompleteMetric";
import TotalActiveAlertsMetric from "../../components/dashboard/metrics/TotalActiveAlertsMetric";
import PreviousWeekOverview from "../../components/dashboard/metrics/PreviousWeekOverview";
import NextWeekOverview from "../../components/dashboard/metrics/NextWeekOverview";

const DashboardOverview = () => {
    const {setCurrentTab, currentProperty} = useOutletContext();
    const [propertyId] = useState(currentProperty.propertyId);
    const [date, setDate] = useState();

    useEffect( () => {
        setCurrentTab("overview");
        let newDate = new Date()
        let options = {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'}
        setDate(newDate.toLocaleDateString('en-GB', options));
    }, [])

    useEffect( () => {
        document.title = "Dashboard | Glan"
    }, [])

    return (
        <>
        <h1 className="dashboard-title mx-1">{date}</h1>

        <div className="row metrics d-flex justify-content-around my-3 mx-1">
            <div className="col-lg-3 metric my-2">
                <TotalCleansMetric propertyId={propertyId} />
            </div>
            <div className="col-lg-3 metric my-2">
                <TotalTasksCompleteMetric propertyId={propertyId} />
            </div>
            <div className="col-lg-3 metric my-2">
                <TotalActiveAlertsMetric propertyId={propertyId} />
            </div>
            <div className="col-lg-3 metric my-2">
                <CurrentCleanersCodeMetric propertyId={propertyId} />
            </div>
        </div>
        <div className="row metrics d-none d-md-flex justify-content-around my-3 mx-1">
            <div className="col-lg-6 metric-large my-2">
                <PreviousWeekOverview propertyId={propertyId}/>
            </div>
            <div className="col-lg-6 metric-large my-2">
                <NextWeekOverview propertyId={propertyId}/>
            </div>
        </div>
        </>
    )
}

export default DashboardOverview;